import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { Icon } from "react-icons-kit";
import { trash } from "react-icons-kit/iconic/trash";
import { copy } from "react-icons-kit/feather/copy";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DownloadIcon, DeleteIcon, RightChevron } from "../../lib/icons";
import {
  getUploadInboxes,
  //   getFilesByInbox,
  saveUploadInbox,
  deleteUploadInbox
  //   deleteUploadInboxFiles
} from "../../reducers/fileUploadReducer";

import axios from "axios";
// import { clipboard } from "react-icons-kit/iconic/clipboard";
import clipboardCopy from "clipboard-copy";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-046026 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( TELUS Agriculture Solutions )_is_granted_a_( Single Application )_Developer_License_for_the_application_( TABSInsight )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( TABSInsight )_need_to_be_licensed___( TABSInsight )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 October 2024 )____[v2]_MTcyODg2MDQwMDAwMA==995fd12673315dadb6f184b47616c9ca"
);

// const FilesCellRenderer = props => {
//   const viewFiles = () => {
//     // Here you can define your navigation function
//     // For example:
//     // props.history.push(`/files/${props.data._id}`);
//   };
//   return (
//     <div>
//       {props.value.length}{" "}
//       <div
//         style={{ cursor: "pointer", color: "rgb(110,177,233)" }}
//         onClick={viewFiles}
//       >
//         View
//       </div>
//     </div>
//   );
// };

const LinkCellRenderer = props => {
  const copyLinkToClipboard = () => {
    clipboardCopy(props.value);
    toast.success("Link Copied");
    // clipboard.writeText(props.value);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden"
      }}
    >
      <div
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {props.value}
      </div>
      <Icon
        icon={copy}
        onClick={copyLinkToClipboard}
        style={{ cursor: "pointer", flexShrink: 0 }} // ensures the icon doesn't shrink if text is too long
      />
    </div>
  );
};

const DeleteCellRenderer = props => {
  // Add an onClick handler if you want to handle clicks

  return (
    <div>
      <Icon
        style={{ cursor: "pointer" }}
        icon={trash}
        onClick={() => {
          props.setDeleting(true);
          props.setUploadInbox(props.data);
          // props.deleteUploadInbox(props.data);
          console.log("Trash clicked!");
        }}
      />
    </div>
  );
};
class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Inbox Name",
          field: "name",
          editable: false,
          sortable: true,
          filter: true
        },
        {
          headerName: "Inbox Link",
          field: "link",
          width: 500,
          cellRenderer: "linkCellRenderer"
        },
        {
          headerName: "Created Date",
          field: "created",
          sortable: true,
          filter: true
        },
        {
          headerName: "Created By",
          field: "createdBy",
          sortable: true,
          filter: true
        },
        {
          headName: "Last Upload",
          field: "lastUploadDate",
          sortable: true,
          filter: true
        },
        {
          headerName: "Files",
          field: "files",
          cellRenderer: "filesCellRenderer"
        },
        {
          headerName: "",
          field: "delete",
          cellRenderer: "deleteCellRenderer",
          cellRendererParams: {
            deleteUploadInbox: this.props.deleteUploadInbox,
            setDeleting: this.setDeleting,
            setUploadInbox: this.setUploadInbox
          }
        }
      ],
      selectedInboxCols: [
        {
          headerName: "File Name",
          field: "name",
          editable: false,
          checkboxSelection: true,
          width: 500,
          sortable: true,
          filter: true
        },
        {
          headerName: "File Size",
          field: "fileSize",
          sortable: true,
          cellRenderer: "fileSizeRenderer"
        },
        {
          headerName: "Uploaded Date",
          field: "modifiedDate",
          sortable: true,
          filter: true
        },
        {
          headerName: "Uploaded By",
          field: "createdBy",
          width: 500,
          sortable: true,
          filter: true
        }
      ],
      inboxName: "",
      email: "",
      showAddInbox: false,
      uploadLink: "",
      selectedInbox: null,
      notificationEmails: "",

      selectedFiles: [],
      doUpdateFiles: false,
      showDeleteFileModal: false,

      deleteUploadInboxConfirm: false,
      uploadInbox: {},
      uploadInboxName: ""
    };
    this.handleClose = this.handleClose.bind(this);
    this.addInbox = this.addInbox.bind(this);
    this.filesCellRenderer = this.filesCellRenderer.bind(this);
    this.fileSizeRenderer = this.fileSizeRenderer.bind(this);
    this.viewFiles = this.viewFiles.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.props.getUploadInboxes();
    this.deleteHandler = this.deleteHandler.bind(this);
    this.setDeleting = this.setDeleting.bind(this);
    this.setUploadInbox = this.setUploadInbox.bind(this);
    this.deleteInboxHandler = this.deleteInboxHandler.bind(this);
  }
  setDeleting = isDeleting => {
    console.log("DELETING THIS");
    this.setState({ deleteUploadInboxConfirm: isDeleting });
  };
  setUploadInbox = inbox => {
    this.setState({ uploadInbox: inbox, uploadInboxName: inbox.name });
  };
  deleteInboxHandler() {
    this.props.deleteUploadInbox(this.state.uploadInbox);
    this.setState({ deleteUploadInboxConfirm: false });
  }

  onRowSelected(event) {
    // console.log("ON ROW SELECTED");
    // console.log(event.node.selected);
    // console.log(event.api.getSelectedRows());
    // debugger;
    this.setState({ selectedFiles: event.api.getSelectedRows() });
  }
  handleClose() {
    this.setState({
      showAddInbox: false,
      showDeleteFileModal: false,
      deleteUploadInboxConfirm: false
    });
  }
  generateUploadLink() {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/app/setUploadToken`,
        {},
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        console.log(res.data);
        this.setState({ uploadLink: res.data.token });
      });
  }
  componentDidMount() {}
  componentDidUpdate() {
    if (this.state.doUpdateFiles && !this.props.loading) {
      //selectedInbox
      let selectedInbox =
        this.props.uploadInboxes.find(item => {
          return item._id === this.state.selectedInbox._id;
        }) || null;
      this.setState({ doUpdateFiles: false, selectedInbox });
    }
  }
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }

  addInbox() {
    let notifyEmails = "";
    if (this.state.emails !== undefined) {
      const nofityEmailsArr = this.state.emails.split(",").map(item => {
        return item.trim();
      });
      notifyEmails = nofityEmailsArr.join(",");
    }

    this.props.saveUploadInbox(this.state.inboxName, notifyEmails);
    this.handleClose();
  }
  viewFiles(props) {
    console.log(props);

    this.setState({
      selectedInbox: props.data,
      notificationEmails: props.data.notify
    });
  }
  fileSizeRenderer(params) {
    if (params.value === undefined || params.value === null) return "";

    // Convert bytes to a more readable format
    const bytes = params.value;
    if (bytes < 1024) return bytes + " B";
    else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + " kB";
    else if (bytes < 1024 * 1024 * 1024)
      return (bytes / (1024 * 1024)).toFixed(1) + " MB";
    return (bytes / (1024 * 1024 * 1024)).toFixed(1) + " GB";
  }
  filesCellRenderer(props) {
    console.log("Rendering files cell", props);
    return (
      <div>
        {props.value.length}{" "}
        <a
          style={{ cursor: "pointer", color: "rgb(110,177,233)" }}
          onClick={() => {
            this.viewFiles(props);
          }}
        >
          View
        </a>
      </div>
    );
  }
  deleteHandler() {
    const { selectedInbox } = this.state;
    const rmIds = this.state.selectedFiles.map(item => {
      return item._id;
    });
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteUploadInboxFiles`,
        {
          uploadInbox: selectedInbox._id,
          fileIds: rmIds
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(async res => {
        await this.props.getUploadInboxes();
        this.setState({ doUpdateFiles: true, showDeleteFileModal: false });
        // console.log(res.data);
        // this.setState({ uploadLink: res.data.token });
      })
      .catch(err => {
        this.setState({ showDeleteFileModal: false });
      });
  }
  render() {
    const {
      showAddInbox,
      selectedInbox,
      showDeleteFileModal,
      selectedFiles,
      deleteUploadInboxConfirm,
      uploadInboxName
    } = this.state;

    if (selectedInbox === null) {
      return (
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <Modal isOpen={deleteUploadInboxConfirm}>
            <ModalHeader>Confirm Deletion</ModalHeader>

            <ModalBody>
              <div>
                Are you sure you want to delete the inbox '{uploadInboxName}'?
              </div>
            </ModalBody>

            <ModalFooter>
              <Button bsstyle="primary" onClick={this.deleteInboxHandler}>
                Delete
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={showAddInbox}>
            <ModalHeader>Create New Inbox</ModalHeader>

            <ModalBody>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>Inbox Name</div>
                <input
                  value={this.state.inboxName}
                  onChange={evt =>
                    this.setState({ inboxName: evt.target.value })
                  }
                />
                <div style={{ marginTop: "10px" }}>
                  Upload Notification Emails
                </div>
                <div>(comma separated list)</div>
                <input
                  value={this.state.emails}
                  onChange={evt => this.setState({ emails: evt.target.value })}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <Button bsstyle="primary" onClick={this.addInbox}>
                Save
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "5px"
            }}
          >
            <div className="page-title">File Upload Inboxes</div>
            <div
              className="ta-button ta-button-primary"
              onClick={() => {
                this.setState({ showAddInbox: true });
              }}
            >
              New Inbox
            </div>
          </div>
          <div
            className="ag-theme-balham"
            style={{
              height: "99%",
              width: "100%"
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={this.props.uploadInboxes}
              enableSorting={true}
              enableFilter={true}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              filter={"agTextColumnFilter"}
              frameworkComponents={{
                linkCellRenderer: LinkCellRenderer,
                deleteCellRenderer: DeleteCellRenderer,
                filesCellRenderer: this.filesCellRenderer,
                fileSizeRenderer: this.fileSizeRenderer
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <Modal isOpen={showDeleteFileModal}>
            <ModalHeader>Confirm Deletion</ModalHeader>

            <ModalBody style={{ display: "flex", flexDirection: "column" }}>
              <div>Are you sure you want to delete the following file(s): </div>

              <div>
                {selectedFiles.map((item, idx) => {
                  return <div key={idx}>{item.name}</div>;
                })}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button bsstyle="primary" onClick={this.deleteHandler}>
                Delete
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <span className="page-title">Files for '</span>
              <span style={{ fontStyle: "italic" }}>
                {this.state.selectedInbox.name}'{" "}
              </span>
              <span className="page-title">Inbox</span>
            </div>
            {/* <div style={{ width: "40%" }}></div> */}
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                marginBottom: "10px"
              }}
            >
              <div
                className="ta-button ta-button-secondary"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  width: "85px",
                  alignItems: "center"
                }}
                onClick={() => {
                  this.setState({
                    selectedInbox: null,
                    notificationEmails: ""
                  });
                }}
              >
                {/* <div className="arrow-left"></div> */}
                <div>
                  <RightChevron
                    height={"16px"}
                    width={"16px"}
                    color={"rgb(88, 89, 91)"}
                    // viewBox={"-1 2 24 24"}
                    viewBox={"0 2 16 16"}
                    style={{
                      cursor: "pointer",
                      marginRight: "5px",
                      transform: "rotate(180deg)"
                    }}
                  />
                </div>
                Back
              </div>
              <div
                className="ta-button ta-button-secondary"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
                onClick={() => {
                  // console.log(this.state.selectedFiles);
                  // const fileArr = this.state.selectedFile.fullPath.split("/");
                  // let fileContainer =
                  //   this.state.selectedFile.fullPath.split("/")[0];
                  // let filePath = this.state.selectedFile.fullPath.split(
                  //   fileContainer + "/"
                  // )[1];
                  // var fileName = fileArr[fileArr.length - 1];
                  const fileIds = this.state.selectedFiles.map(item => {
                    return item._id;
                  });
                  axios
                    .post(
                      `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/downloadFiles`,
                      {
                        fileIds
                      },
                      {
                        headers: {
                          ClientToken: localStorage.getItem("clientToken")
                        },
                        responseType: "arraybuffer"
                      }
                    )
                    .then(res => {
                      const fileName = decodeURIComponent(
                        res.headers["content-disposition"].split("=")[1]
                      );
                      const contentType =
                        res.headers["content-type"] ||
                        "application/octet-stream"; // get content-type from response headers or set default value
                      const blob = new Blob([res.data], { type: contentType }); // Create Blob from arraybuffer response
                      const url = window.URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = url;
                      if (this.state.selectedFiles.length === 1) {
                        link.setAttribute("download", fileName);
                      } else {
                        let sDate = new Date().toISOString();
                        link.setAttribute(
                          "download",
                          selectedInbox.name.replace(/ /g, "_") +
                            "__" +
                            sDate +
                            "__.zip"
                        );
                      }
                      document.body.appendChild(link);
                      link.click();
                    });
                }}
              >
                <DownloadIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgb(88, 89, 91)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "5px"
                  }}
                />
                Download
              </div>
              <div
                className="ta-button ta-button-secondary"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
                onClick={() => {
                  // console.log("Trash clicked!");
                  if (this.state.selectedFiles !== null) {
                    this.setState({
                      showDeleteFileModal: true
                    });
                  }
                  // this.setState({ selectedInbox: "" });
                }}
              >
                <DeleteIcon
                  height={"16px"}
                  width={"16px"}
                  color={"rgb(88, 89, 91)"}
                  style={{
                    cursor: "pointer",
                    marginRight: "5px"
                  }}
                />
                Delete
              </div>
            </div>
          </div>
          <div
            className="ag-theme-balham"
            style={{
              height: "99%",
              width: "100%"
            }}
          >
            <AgGridReact
              columnDefs={this.state.selectedInboxCols}
              rowData={this.state.selectedInbox.files}
              enableSorting={true}
              enableFilter={true}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              filter={"agTextColumnFilter"}
              onRowSelected={e => {
                this.onRowSelected(e);
              }}
              frameworkComponents={{
                fileSizeRenderer: this.fileSizeRenderer
              }}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <div>Upload Notification Emails</div>
            <div>(comma separated list)</div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                value={this.state.notificationEmails}
                style={{ width: "500px" }}
                onChange={evt =>
                  this.setState({ notificationEmails: evt.target.value })
                }
              />
              <div
                className="ta-button ta-button-primary"
                onClick={() => {
                  const nofityEmailsArr = this.state.notificationEmails
                    .split(",")
                    .map(item => {
                      return item.trim();
                    });
                  const notifyEmails = nofityEmailsArr.join(",");
                  axios
                    .post(
                      `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveNotifyEmails`,
                      {
                        uploadInbox: selectedInbox._id,
                        notifyEmail: notifyEmails
                      },
                      {
                        headers: {
                          ClientToken: localStorage.getItem("clientToken")
                        }
                      }
                    )
                    .then(res => {
                      console.log(res.data);
                      this.setState({ uploadLink: res.data.token });
                    });
                }}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  uploadInboxes: state.fileUploadReducer.uploadInboxes,
  inboxFiles: state.fileUploadReducer.inboxFiles,

  loading: state.fileUploadReducer.loading
});
const mapDispatchToProps = dispatch => ({
  saveUploadInbox(name, emails) {
    dispatch(saveUploadInbox(name, emails));
  },
  getUploadInboxes() {
    dispatch(getUploadInboxes());
  },
  deleteUploadInbox(inbox) {
    dispatch(deleteUploadInbox(inbox));
  }
  //   getReportSubscriptions() {
  //     dispatch(getReportSubscriptions());
  //   },
  //   getReportSubscriptionsByClient(client) {
  //     dispatch(getReportSubscriptionsByClient(client));
  //   },
  //   saveReportSubscription(rptSub, client) {
  //     dispatch(saveReportSubscription(rptSub, client));
  //   },
  //   deleteReportSubscription(rptSubId, client) {
  //     dispatch(deleteReportSubscription(rptSubId, client));
  //   }
});
export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
