import React, { Component } from "react";

import { connect } from "react-redux";
import {
  setAllStatus,
  resetAllStatus,
  saveAllWorkspaces,
  filterAllWorkspaces,
  filterGroupWorkspaces
} from "../../reducers/workspaceReducer";
import {
  setUnsavedGroup,
  filterWorkspaces,
  getGroups
} from "../../reducers/groupReducer";
import { cloneDeep } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../components/style.css";
import WorkspaceCheckbox from "./workspaceCheckbox";

class Workspaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWorkspaceModal: false,
      allWorkspaces: null,
      searchValue: ""
    };
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.renderAllWorkspaces = this.renderAllWorkspaces.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMainInputChange = this.handleMainInputChange.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
  }
  clearSearchInput() {
    if (this.workspaceSearch) {
      this.workspaceSearch.value = "";
    }
    this.setState({ searchValue: "" });
  }

  componentDidMount() {
    this.props.filterGroupWorkspaces("", this.props.searchWorkspaces);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchWorkspaces !== this.props.searchWorkspaces) {
      this.props.filterGroupWorkspaces("", this.props.searchWorkspaces);
    }
    if (prevProps.selectedGroup !== this.props.selectedGroup) {
      this.clearSearchInput();
    }
  }

  handleClose() {
    this.setState({ showWorkspaceModal: false });
    this.props.resetAllStatus(this.props.allWorkspaces);
  }
  handleSave() {
    this.props.saveAllWorkspaces(
      this.props.selectedClient.value,
      this.props.allWorkspaces
    );

    this.setState({ showWorkspaceModal: false });
    this.props.getGroups(this.props.selectedClient.value);
  }

  renderAllWorkspaces() {
    const { allLoading, searchAllWorkspaces } = this.props;

    if (!allLoading) {
      if (searchAllWorkspaces && searchAllWorkspaces.length !== 0) {
        return searchAllWorkspaces
          .sort((a, b) => {
            const nameA = a.name ? a.name.toLowerCase() : "";
            const nameB = b.name ? b.name.toLowerCase() : "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
          .map((workspace, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <WorkspaceCheckbox
                  index={index}
                  key={index}
                  workspace={workspace}
                  checkboxChangeHandler={(event, workspace) => {
                    this.checkboxChangeHandler(event, workspace, "all");
                  }}
                />
              </div>
            );
          });
      }
    }
    return null;
  }

  checkboxChangeHandler(event, workspace, type) {
    //workspace.checked = !workspace.checked;
    //the type "all" here is for the Global Workspace Dialog i.e. +Add workspace
    if (type === "all") {
      workspace.checked = event.target.checked;
      this.props.setAllStatus(workspace);
    } else {
      let newWorkspaces = cloneDeep(this.props.workspaces);
      newWorkspaces = newWorkspaces.map(mapWorkspace => {
        if (workspace.id === mapWorkspace.id) {
          mapWorkspace.checked = event.target.checked;
        }
        return mapWorkspace;
      });
      this.props.setUnsavedGroup(
        this.props.selectedGroup,
        newWorkspaces,
        this.props.modelRoles
      );
    }
  }
  renderWorkspaces(workspaces) {
    const { ugLoading, loading } = this.props;

    if (!loading && workspaces.length > 0) {
      return workspaces
        .sort((a, b) => {
          const nameA = a.name ? a.name.toLowerCase() : "";
          const nameB = b.name ? b.name.toLowerCase() : "";
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .map((workspace, index) => {
          return (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <WorkspaceCheckbox
                index={index}
                workspace={workspace}
                checkboxChangeHandler={(event, workspace) => {
                  this.checkboxChangeHandler(event, workspace, "client");
                }}
              />
            </div>
          );
        });
    } else if (ugLoading) {
      return (
        <div className="ta-info-text">Select a Client/Group to Populate</div>
      );
      //This was to inform a user that there are no workspaces associted with this client
      //but couldnt get a way for this to work properly
      // } else if (
      //   (workspaces === undefined || workspaces.length <= 0) &&
      //   this.props.selectedGroup !== null
      // ) {
      //   debugger;
      //   return (
      //     <div className="ta-info-text">
      //       Add Some Workspaces to this Client with the Add Workspace Button
      //     </div>
      //   );
    } else {
      return <div className="ta-info-text">Select a Group to Populate</div>;
    }
  }
  handleInputChange() {
    const searchValue = this.allWorkspaceSearch?.value || "";
    this.setState({ searchValue });
    this.props.filterAllWorkspaces(searchValue, this.props.allWorkspaces);
  }

  handleMainInputChange() {
    const searchValue = this.workspaceSearch?.value || "";
    this.setState({ searchValue });
    this.props.filterGroupWorkspaces(searchValue, this.props.searchWorkspaces);
  }

  render() {
    const { type, allWorkspaces, searchGroupWorkspaces } = this.props;
    const { showWorkspaceModal } = this.state;
    //var cLoading = true;

    let cWorkspaces = null;
    if (type === "all") {
      //cLoading = allLoading;
      cWorkspaces = allWorkspaces;
    } else {
      //cLoading = loading;

      cWorkspaces = searchGroupWorkspaces;
      if (cWorkspaces === undefined) {
        cWorkspaces = [];
      }
    }
    // if (cLoading) {
    //   return <div>Loading</div>;
    // }

    return (
      <div
        className="workspace-div"
        style={{ minWidth: "40%", display: "flex", flexDirection: "column" }}
      >
        <div>
          <Modal isOpen={showWorkspaceModal}>
            <ModalHeader>Add PowerBI Workspaces</ModalHeader>

            <ModalBody style={{ height: "600px" }}>
              <input
                className="input-box"
                placeholder="search"
                ref={input => (this.allWorkspaceSearch = input)}
                onChange={this.handleInputChange}
                style={{ width: "60%" }}
              />
              <div
                style={{
                  marginTop: "5px",
                  height: "540px",
                  overflowY: "scroll"
                }}
              >
                {this.renderAllWorkspaces()}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button bsstyle="primary" onClick={this.handleSave}>
                Save changes
              </Button>
              <Button onClick={this.handleClose}>Close</Button>
            </ModalFooter>
          </Modal>
        </div>
        <div className="workspace-title-div">
          <div
            style={{ fontSize: "16px", fontWeight: "500", marginRight: "10px" }}
          >
            Workspaces
          </div>
          <div
            onClick={event => {
              if (this.props.selectedClient !== null)
                this.setState({
                  showWorkspaceModal: true,
                  allWorkspaces: cloneDeep(this.props.allWorkspaces)
                });
            }}
            className="ta-add-button-right ta-button ta-button-primary "
          >
            +Add Workspace
          </div>
        </div>
        <input
          className="input-box"
          placeholder="search"
          ref={input => (this.workspaceSearch = input)}
          onChange={this.handleMainInputChange}
          style={{ width: "60%" }}
        />
        <div
          style={{
            border: "1px solid #CCC",
            padding: "4px",
            overflow: "auto",
            marginTop: "10px"
          }}
        >
          {this.renderWorkspaces(cWorkspaces)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.workspaceReducer.loading,
  error: state.workspaceReducer.error,
  allWorkspaces: state.workspaceReducer.allWorkspaces,
  searchAllWorkspaces: state.workspaceReducer.searchAllWorkspaces,
  searchWorkspaces: state.workspaceReducer.searchWorkspaces,
  searchGroupWorkspaces: state.workspaceReducer.searchGroupWorkspaces,
  allLoading: state.workspaceReducer.allLoading,
  ugLoading: state.groupReducer.loading,
  selectedClient: state.clientReducer.selectedClient,
  selectedGroup: state.groupReducer.selectedGroup,
  modelRoles: state.groupReducer.modelRoles
});

const mapDispatchToProps = dispatch => ({
  setAllStatus(workspaces) {
    dispatch(setAllStatus(workspaces));
  },
  resetAllStatus(workspaces) {
    dispatch(resetAllStatus(workspaces));
  },
  saveAllWorkspaces(client, workspaces) {
    dispatch(saveAllWorkspaces(client, workspaces));
  },
  filterWorkspaces(filter, workspaces, group) {
    dispatch(filterWorkspaces(filter, workspaces, group));
  },
  filterAllWorkspaces(filter, workspaces) {
    dispatch(filterAllWorkspaces(filter, workspaces));
  },
  filterGroupWorkspaces(filter, workspaces) {
    dispatch(filterGroupWorkspaces(filter, workspaces));
  },
  setUnsavedGroup(group, workspaces, modelRoles) {
    dispatch(setUnsavedGroup(group, workspaces, modelRoles));
  },
  getGroups(client) {
    dispatch(getGroups(client));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Workspaces);
