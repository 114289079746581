import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import FileFolder from "./fileFolder";
import { connect } from "react-redux";
import "../style.css";
import File from "./file";

class FileListFolder extends Component {
  componentDidUpdate() {
    // ReactTooltip.rebuild();
  }
  renderFileFolders(folders, index) {
    return (
      <FileFolder key={index} index={index} item={folders}>
        <div>
          {folders.children.map((file, index) => {
            if (file.type === "file") {
              return (
                <File
                  file={file}
                  key={index}
                  index={index}
                  className="ta-workspace-reports"
                />
              );
            } else {
              return this.renderFileFolders(file, index);
            }
          })}
        </div>
      </FileFolder>
    );
  }

  render() {
    const { fileList } = this.props;
    if (fileList === undefined || fileList.length === 0) {
      return (
        <div
          className="ta-report-list-container"
          style={{ overflow: "hidden" }}
        >
          <div>
            <div
              style={{
                borderBottom: "1px solid grey",
                marginBottom: "10px",
                paddingRight: "30px"
              }}
            >
              <div className="ta-ar">
                <div style={{ marginLeft: "auto", paddingRight: "110px" }}>
                  Created
                </div>
                <div style={{ paddingRight: "15px" }}>Modified</div>
                <div style={{ paddingRight: "15px" }}>Last Refresh</div>
                <div style={{ paddingRight: "15px" }}>Status</div>
                <div>Auto Refresh</div>
              </div>
            </div>
            Please Select A Client
          </div>
        </div>
      );
    }
    return (
      <div className="ta-report-list-container" style={{ overflow: "hidden" }}>
        <div
          style={{
            borderBottom: "1px solid grey",
            paddingRight: "30px",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 1
          }}
        >
          <div className="ta-ar">
            <div style={{ marginLeft: "auto", paddingRight: "180px" }}>
              Created
            </div>
            <div style={{ paddingRight: "120px" }}>Modified</div>
            <div style={{ paddingRight: "15px" }}>Last Refresh</div>
            <div style={{ paddingRight: "0px" }}>Status</div>
            <div>Auto Refresh</div>
          </div>
        </div>
        <div style={{ overflowY: "scroll", height: "100%" }}>
          <div className="ta-top-level-folder">
            {fileList.map((file, fIndex) => {
              if (file.children === undefined) {
                return (
                  <div key={fIndex} index={fIndex}>
                    <File
                      file={file}
                      key={fIndex}
                      index={fIndex}
                      className="ta-workspace-reports"
                    />
                  </div>
                );
              } else {
                return this.renderFileFolders(file, fIndex);
              }
            })}
            <Tooltip delayShow={500} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

export default connect("", mapDispatchToProps)(FileListFolder);
