import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  userGroups: [],
  loading: true,
  error: null,
  selectedGroup: null,
  searchGroups: [],
  unsavedChanges: false,
  authType: "",
  modelRoles: []
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_GROUPS_BEGIN = "FETCH_GROUPS_BEGIN";
const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_SUCCESS";
const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_FAILURE";
const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
const ADD_GROUP_BEGIN = "ADD_GROUP_BEGIN";
const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
const ADD_GROUP_FAILURE = "ADD_GROUP_FAILURE";
const REMOVE_GROUP_BEGIN = "REMOVE_GROUP_BEGIN";
const REMOVE_GROUP_SUCCESS = "REMOVE_GROUP_SUCCESS";
const REMOVE_GROUP_FAILURE = "REMOVE_GROUP_FAILURE";
const SAVE_GROUP_BEGIN = "SAVE_GROUP_BEGIN";
const SAVE_GROUP_SUCCESS = "SAVE_GROUP_SUCCESS";
const SAVE_GROUP_FAILURE = "SAVE_GROUP_FAILURE";
const SET_SEARCH_GROUP = "SET_SEARCH_GROUP";
const UPDATE_GROUP_NAME = "UPDATE_GROUP_NAME";
const SET_UNSAVED_GROUP = "SET_UNSAVED_GROUP";
const CANCEL_GROUP_CHANGES = "CANCEL_GROUP_CHANGES";
const SET_SEARCH_WORKSPACES = "SET_SEARCH_WORKSPACES";
const SET_MODEL_ROLES = "SET_MODEL_ROLES";
const SET_GROUP_SELECTION = "SET_GROUP_SELECTION";
const ADD_BLANK_GROUP = "ADD_BLANK_GROUP";

/* -------------------- ACTION CREATORS -------------------- */

const fetchGroupsBegin = () => ({
  type: FETCH_GROUPS_BEGIN
});
const fetchGroupsSuccess = (groups, authType) => {
  return { type: FETCH_GROUPS_SUCCESS, payload: groups, authType };
};
const fetchGroupsError = error => ({
  type: FETCH_GROUPS_FAILURE,
  payload: { error }
});
const setSelectedGroup = group => {
  return { type: SET_SELECTED_GROUP, payload: group };
};
const doSetModelRoles = rRoles => {
  return { type: SET_MODEL_ROLES, payload: rRoles };
};
const addUserGroupBegin = () => ({
  type: ADD_GROUP_BEGIN
});
export const addUserGroupSuccess = group => {
  return { type: ADD_GROUP_SUCCESS, payload: group };
};
const addUserGroupError = error => ({
  type: ADD_GROUP_FAILURE,
  payload: { error }
});
const removeUserGroupBegin = () => ({
  type: REMOVE_GROUP_BEGIN
});
const removeUserGroupSuccess = groupId => {
  return { type: REMOVE_GROUP_SUCCESS, payload: groupId };
};
const removeUserGroupError = error => ({
  type: REMOVE_GROUP_FAILURE,
  payload: { error }
});
const saveGroupBegin = () => ({
  type: SAVE_GROUP_BEGIN
});
const saveGroupSuccess = group => {
  return { type: SAVE_GROUP_SUCCESS, payload: group };
};
const saveGroupError = error => ({
  type: SAVE_GROUP_FAILURE,
  payload: { error }
});
const setSearchGroup = groups => {
  return { type: SET_SEARCH_GROUP, payload: groups };
};

const updateGroupNameSuccess = group => {
  return { type: UPDATE_GROUP_NAME, payload: group };
};
const doSetUnsavedGroup = (group, workspaces, modelRoles) => {
  return {
    type: SET_UNSAVED_GROUP,
    group: group,
    workspaces: workspaces,
    modelRoles
  };
};
const doCancelGroupChanges = () => ({
  type: CANCEL_GROUP_CHANGES
});
const doAddBlankGroup = () => ({
  type: ADD_BLANK_GROUP
});

const setSearchWorkspaces = (workspaces, groupName) => {
  return {
    type: SET_SEARCH_WORKSPACES,
    workspaces: workspaces,
    groupName: groupName
  };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const filterGroups = (filter, groups) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchGroup(groups));
    } else {
      let newGroups = cloneDeep(groups);
      newGroups = newGroups.filter(group => {
        return group.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      });
      dispatch(setSearchGroup(newGroups));
    }
  };
};
export const getGroups = client => {
  return dispatch => {
    dispatch(fetchGroupsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getUserGroups`,
        {
          client: client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(
          fetchGroupsSuccess(response.data.userGroups, response.data.authType)
        );
      })
      .catch(error => dispatch(fetchGroupsError(error)));
  };
};
export const addBlankGroup = () => {
  return dispatch => {
    dispatch(doAddBlankGroup());
  };
};
export const addUserGroup = (client, groupName) => {
  return dispatch => {
    dispatch(addUserGroupBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/addGroup`,
        {
          client,
          groupName
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(addUserGroupSuccess(response.data.userGroup));
      })
      .catch(error => dispatch(addUserGroupError(error)));
  };
};
export const removeGroup = (client, groupId) => {
  return dispatch => {
    dispatch(removeUserGroupBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/removeGroup`,
        {
          client,
          groupId
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(removeUserGroupSuccess(groupId));
        dispatch(getGroups(client));
      })
      .catch(error => dispatch(removeUserGroupError(error)));
  };
};
export const saveGroup = (client, groups) => {
  return dispatch => {
    dispatch(saveGroupBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveGroup`,
        {
          client: client,
          groups: groups
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // console.log(response);
        dispatch(saveGroupSuccess(response.data.userGroup));
      })
      .catch(error => dispatch(saveGroupError(error)));
  };
};
export const updateGroupName = (client, groupId, newName) => {
  return dispatch => {
    //dispatch(updateGroupNameBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/updateGroup`,
        {
          client,
          groupId,
          newName
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // console.log(response.data);
        dispatch(updateGroupNameSuccess(response.data.workspace));
      });
    //.catch(error => dispatch(removeGroupNameError(error)));
  };
};
export const setGroup = group => {
  return dispatch => {
    dispatch(setSelectedGroup(group));
  };
};
export const setModelRoles = rRoles => {
  return dispatch => {
    dispatch(doSetModelRoles(rRoles));
  };
};
export const setUnsavedGroup = (group, workspaces, modelRoles) => {
  return dispatch => {
    dispatch(doSetUnsavedGroup(group, workspaces, modelRoles));
  };
};
export const cancelGroupChanges = () => {
  return dispatch => {
    dispatch(doCancelGroupChanges());
  };
};
export const filterWorkspaces = (filter, workspaces, group) => {
  return dispatch => {
    if (filter === "") {
      dispatch(setSearchWorkspaces(workspaces));
    } else {
      let newWorkspaces = cloneDeep(workspaces);
      newWorkspaces = newWorkspaces.filter(workspace => {
        return (
          workspace.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        );
      });
      dispatch(setSearchWorkspaces(newWorkspaces, group));
    }
  };
};
export const removeModelRole = (client, groupId, roleId) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/removeModleRole`,
        {
          client,
          groupId,
          roleId
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // console.log(response.data);

        //dispatch(setModelRoles(response.data.modelRole));
        dispatch(getGroups(client));
        //dispatch(updateModelRole(response.data.modelRow));
      });
  };
};
export const addModelRole = (client, groupId, roleName) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/addModelRole`,
        {
          client,
          groupId,
          roleName
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // console.log(response.data);

        dispatch(setModelRoles(response.data.modelRole));
        dispatch(getGroups(client));
        //dispatch(updateModelRole(response.data.modelRow));
      });
  };
};

export const setGroupSelection = selGroups => ({
  type: SET_GROUP_SELECTION,
  payload: selGroups
});

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_GROUPS_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        userGroups: action.payload,
        searchGroups: action.payload,
        authType: action.authType,
        loading: false
      };
    case FETCH_GROUPS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload
      };
    case ADD_GROUP_BEGIN:
      return { ...state, loading: true };
    case ADD_GROUP_SUCCESS:
      let newGroup = action.payload;
      if (state.userGroups[0] !== undefined) {
        newGroup.workspaces = state.userGroups[0].workspaces.map(workspace => {
          workspace.checked = false;
          return workspace;
        });
      }

      let updateUserGroups = state.userGroups;
      updateUserGroups.push(newGroup);
      return { ...state, userGroups: updateUserGroups, loading: false };
    case ADD_GROUP_FAILURE:
      return { ...state };
    case SAVE_GROUP_BEGIN:
      return { ...state, loading: true };
    case SAVE_GROUP_SUCCESS:
      let saveUserGroups = state.userGroups;
      saveUserGroups.forEach(group => {
        if (group.id === action.payload._id) {
          group = Object.assign(group, action.payload);
          group.unsavedChanges = false;
        }
      });
      return {
        ...state,
        userGroups: saveUserGroups,
        loading: false,
        unsavedChanges: false
      };
    case SAVE_GROUP_FAILURE:
      return { ...state, loading: false };
    case REMOVE_GROUP_BEGIN:
      return { ...state };
    case REMOVE_GROUP_SUCCESS:
      let newUserGroups = state.userGroups.filter(n => n.id !== action.payload);
      return { ...state, userGroups: newUserGroups };
    case REMOVE_GROUP_FAILURE:
      return { ...state };
    case SET_SEARCH_GROUP:
      return { ...state, searchGroups: action.payload };
    case UPDATE_GROUP_NAME:
      let updatedUserGroups = state.userGroups.map(group => {
        if (group.id === action.payload._id) {
          group.name = action.payload.name;
        }
        return group;
      });
      let updatedSearchUserGroups = state.searchGroups.map(group => {
        if (group.id === action.payload._id) {
          group.name = action.payload.name;
        }
        return group;
      });
      return {
        ...state,
        userGroups: updatedUserGroups,
        searchGroups: updatedSearchUserGroups
      };
    case SET_UNSAVED_GROUP:
      let unsavedUserGroups = state.userGroups.map(group => {
        if (group.id === action.group) {
          group.unsavedChanges = true;
          group.workspaces = action.workspaces;
          group.modelRoles = action.modelRoles;
        }
        return group;
      });

      let unsavedSearchUserGroups = state.searchGroups.map(group => {
        if (group.id === action.payload) {
          group.unsavedChanges = true;
        }
        return group;
      });
      return {
        ...state,
        userGroups: unsavedUserGroups,
        modelRoles: action.modelRoles,
        searchGroups: unsavedSearchUserGroups,
        unsavedChanges: true
      };
    case ADD_BLANK_GROUP:
      let nSaveUserGroups = state.userGroups;
      nSaveUserGroups.push({
        graphId: "Add Graph Id",
        name: "Add Group Name",
        workspace: []
      });
      return {
        ...state,
        userGroups: nSaveUserGroups,
        loading: false,
        unsavedChanges: false
      };
    case CANCEL_GROUP_CHANGES:
      let cancelUserGroups = state.userGroups.map(group => {
        group.unsavedChanges = false;
        return group;
      });
      let cancelSearchUserGroups = state.searchGroups.map(group => {
        group.unsavedChanges = false;
        return group;
      });
      return {
        ...state,
        userGroups: cancelUserGroups,
        searchGroups: cancelSearchUserGroups,
        unsavedChanges: false
      };
    case SET_SEARCH_WORKSPACES:
      let updateWorkspaces = state.userGroups.map(group => {
        if (group.name === action.groupName) {
          let newGroup = cloneDeep(group);
          newGroup.workspaces = action.workspaces;
          return newGroup;
        }
        return group;
      });
      return {
        ...state,
        searchGroups: updateWorkspaces
      };
    case SET_MODEL_ROLES:
      let newRoles = cloneDeep(state.modelRoles);
      if (newRoles.length === 0) {
        newRoles.push(...action.payload);
      }
      if (action.payload.length === undefined) {
        newRoles.push(action.payload);
      } else {
        newRoles = action.payload;
      }

      return { ...state, modelRoles: newRoles };
    case SET_GROUP_SELECTION:
      let groups = cloneDeep(state.searchGroups);
      groups = groups.map(item => {
        if (action.payload.includes(item.id)) {
          item.customAppchecked = true;
        } else {
          item.customAppchecked = false;
        }
        return item;
      });

      return { ...state, searchGroups: groups };
    default:
      return state;
  }
};
