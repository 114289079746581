import React from "react";
import PropTypes from "prop-types";

export class Gear extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        aria-hidden="true"
        data-prefix="fas"
        data-icon="cog"
        className={`svg-inline--fa fa-cog fa-w-16 ${this.props.className}`}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill={this.props.color}
          d="M444.788 291.1l42.616 24.599c4.867 2.809 7.126 8.618 5.459 13.985-11.07 35.642-29.97 67.842-54.689 94.586a12.016 12.016 0 0 1-14.832 2.254l-42.584-24.595a191.577 191.577 0 0 1-60.759 35.13v49.182a12.01 12.01 0 0 1-9.377 11.718c-34.956 7.85-72.499 8.256-109.219.007-5.49-1.233-9.403-6.096-9.403-11.723v-49.184a191.555 191.555 0 0 1-60.759-35.13l-42.584 24.595a12.016 12.016 0 0 1-14.832-2.254c-24.718-26.744-43.619-58.944-54.689-94.586-1.667-5.366.592-11.175 5.459-13.985L67.212 291.1a193.48 193.48 0 0 1 0-70.199l-42.616-24.599c-4.867-2.809-7.126-8.618-5.459-13.985 11.07-35.642 29.97-67.842 54.689-94.586a12.016 12.016 0 0 1 14.832-2.254l42.584 24.595a191.577 191.577 0 0 1 60.759-35.13V25.759a12.01 12.01 0 0 1 9.377-11.718c34.956-7.85 72.499-8.256 109.219-.007 5.49 1.233 9.403 6.096 9.403 11.723v49.184a191.555 191.555 0 0 1 60.759 35.13l42.584-24.595a12.016 12.016 0 0 1 14.832 2.254c24.718 26.744 43.619 58.944 54.689 94.586 1.667 5.366-.592 11.175-5.459 13.985L444.788 220.9a193.485 193.485 0 0 1 0 70.2zM336 256c0-44.112-35.888-80-80-80s-80 35.888-80 80 35.888 80 80 80 80-35.888 80-80z"
        />
      </svg>
    );
  }
}
Gear.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class Pen extends React.Component {
  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        style={this.props.style}
        aria-hidden="true"
        data-prefix="fas"
        data-icon="cog"
        className={`${this.props.className}`}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill={this.props.color}
          d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
        />
      </svg>
    );
  }
}
Pen.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class ReportIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={this.props.viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9V7.1c0-0.4-0.4-0.9-0.9-0.9H14c-0.4,0-0.9,0.4-0.9,0.9v9.5C13.1,17,13.5,17.5,14,17.5z
          M20.5,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9V0.9C24,0.4,23.6,0,23.1,0h-2.6c-0.4,0-0.9,0.4-0.9,0.9v15.7C19.6,17,20.1,17.5,20.5,17.5z
          M0.9,17.5h2.6c0.4,0,0.9-0.4,0.9-0.9v-4.3c0-0.4-0.4-0.9-0.9-0.9H0.9c-0.4,0-0.9,0.4-0.9,0.9v4.3C0,17,0.4,17.5,0.9,17.5z
          M7.4,17.5H10c0.4,0,0.9-0.4,0.9-0.9V4.8C10.9,4.4,10.5,4,10,4H7.4C7,4,6.5,4.4,6.5,4.8v11.8C6.5,17,7,17.5,7.4,17.5z"
        />
      </svg>
    );
  }
}
ReportIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class FolderIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 15"
      >
        <path
          className={this.props.className}
          d="M18.1,2.5h-7.5L8.5,0.4C8.3,0.1,7.9,0,7.6,0H1.9C0.8,0,0,0.8,0,1.9v11.3c0,1,0.8,1.9,1.9,1.9h16.3c1,0,1.9-0.8,1.9-1.9V4.4
          C20,3.3,19.2,2.5,18.1,2.5z M18.1,13.1H1.9V1.9h5.5L9.5,4c0.2,0.2,0.6,0.4,0.9,0.4h7.8V13.1z"
        />
      </svg>
    );
  }
}
FolderIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class CheckmarkIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 15"
      >
        <path
          className={this.props.className}
          d="M6.8,14.6L0.3,8.1c-0.4-0.4-0.4-1,0-1.4l1.4-1.4c0.4-0.4,1-0.4,1.4,0l4.4,4.4l9.4-9.4c0.4-0.4,1-0.4,1.4,0l1.4,1.4
          c0.4,0.4,0.4,1,0,1.4L8.2,14.6C7.8,15,7.2,15,6.8,14.6L6.8,14.6z"
        />
      </svg>
    );
  }
}
CheckmarkIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class RightChevron extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={this.props.viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          transform={this.props.transform}
          d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    );
  }
}
RightChevron.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class DeleteIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 20"
      >
        <path
          className={this.props.className}
          d="M0,3.3V2.2c0-0.5,0.4-0.9,0.9-0.9h4.4l0.4-0.7C5.8,0.2,6.2,0,6.5,0H11c0.4,0,0.7,0.2,0.8,0.5l0.4,0.7h4.4
	c0.5,0,0.9,0.4,0.9,0.9v1.1c0,0.3-0.2,0.5-0.5,0.5H0.5C0.2,3.8,0,3.5,0,3.3z M16.3,5.5v12.7c0,1-0.8,1.9-1.9,1.9H3.1
	c-1,0-1.9-0.8-1.9-1.9V5.5C1.3,5.2,1.5,5,1.7,5h14.1C16,5,16.3,5.2,16.3,5.5z M5.6,8.1c0-0.3-0.3-0.6-0.6-0.6S4.4,7.8,4.4,8.1v8.8
	c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6V8.1z M9.4,8.1c0-0.3-0.3-0.6-0.6-0.6S8.1,7.8,8.1,8.1v8.8c0,0.3,0.3,0.6,0.6,0.6
	s0.6-0.3,0.6-0.6V8.1z M13.1,8.1c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v8.8c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
	V8.1z"
        />
      </svg>
    );
  }
}
DeleteIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class AppPie extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25.5 24"
        onClick={this.props.onClick}
      >
        <path
          d="M24.7,13.5H13.6l7.4,7.4c0.3,0.3,0.7,0.3,1,0c1.8-1.7,3.1-4,3.4-6.6C25.6,13.9,25.2,13.5,24.7,13.5L24.7,13.5z M24,10.5
	C23.6,4.9,19.1,0.4,13.5,0c-0.4,0-0.8,0.3-0.8,0.8v10.5h10.5C23.7,11.2,24,10.9,24,10.5L24,10.5z M10.5,13.5V2.4
	c0-0.4-0.4-0.8-0.8-0.8C4.1,2.4-0.2,7.3,0,13.1c0.2,6,5.4,10.9,11.4,10.9c2.4,0,4.5-0.8,6.3-2.1c0.4-0.3,0.4-0.8,0.1-1.1L10.5,13.5z"
        />
      </svg>
    );
  }
}
AppPie.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartLineDown extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}
      >
        <path
          d="M11.3,11.4c0.4,0.4,1,0.4,1.4,0L18,6.1l5.5,5.5l-1.8,1.8c-0.9,0.9-0.3,2.6,1.1,2.6H29c0.6,0,1-0.4,1-1V8.7
	c0-1.3-1.6-2-2.6-1.1l-1.8,1.8l-6.9-6.9c-0.4-0.4-1-0.4-1.4,0L12,7.9L8.8,4.6c-0.4-0.4-1-0.4-1.4,0L6.6,5.4c-0.4,0.4-0.4,1,0,1.4
	L11.3,11.4z M31,21H3V1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v21c0,1.1,0.9,2,2,2h29c0.6,0,1-0.4,1-1v-1C32,21.4,31.6,21,31,21z"
        />
      </svg>
    );
  }
}
AppChartLineDown.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartLine extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}
      >
        <path
          d="M7.4,13.4c0.4,0.4,1,0.4,1.4,0l3.2-3.2l5.3,5.3c0.4,0.4,1,0.4,1.4,0l6.9-6.9l1.8,1.8c0.9,0.9,2.6,0.3,2.6-1.1V3
	c0-0.6-0.4-1-1-1h-6.3c-1.3,0-2,1.6-1.1,2.6l1.8,1.8L18,11.9l-5.3-5.3c-0.4-0.4-1-0.4-1.4,0l-4.6,4.6c-0.4,0.4-0.4,1,0,1.4
	C6.6,12.6,7.4,13.4,7.4,13.4z M31,21H3V1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v21c0,1.1,0.9,2,2,2h29c0.6,0,1-0.4,1-1v-1
	C32,21.4,31.6,21,31,21z"
        />
      </svg>
    );
  }
}
AppChartLine.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartBar extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}
      >
        <path
          d="M24.8,18h1.4c0.4,0,0.8-0.4,0.8-0.8V2.8C27,2.4,26.6,2,26.2,2h-1.4C24.4,2,24,2.4,24,2.8v14.4C24,17.6,24.4,18,24.8,18z
	 M12.8,18h1.4c0.4,0,0.8-0.4,0.8-0.8V4.8C15,4.4,14.6,4,14.2,4h-1.4C12.4,4,12,4.4,12,4.8v12.4C12,17.6,12.4,18,12.8,18z M18.8,18
	h1.4c0.4,0,0.8-0.4,0.8-0.8V8.8C21,8.4,20.6,8,20.2,8h-1.4C18.4,8,18,8.4,18,8.8v8.4C18,17.6,18.4,18,18.8,18z M31,21H3V1
	c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v21c0,1.1,0.9,2,2,2h29c0.6,0,1-0.4,1-1v-1C32,21.4,31.6,21,31,21z M6.8,18h1.4
	C8.6,18,9,17.6,9,17.2v-4.4C9,12.4,8.6,12,8.2,12H6.8C6.4,12,6,12.4,6,12.8v4.4C6,17.6,6.4,18,6.8,18z"
        />
      </svg>
    );
  }
}
AppChartBar.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartUser extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 24"
        onClick={this.props.onClick}
      >
        <path
          d="M9.8,16.5H9.6c-0.7,0.2-1.3,0.4-2.1,0.4s-1.4-0.1-2.1-0.4H5.3c-2.9,0-5.3,2.4-5.3,5.3C0,23,1,24,2.3,24h10.5
	c1.2,0,2.3-1,2.3-2.3C15,18.9,12.6,16.5,9.8,16.5z M27.8,0h-18C8.5,0,7.5,1,7.5,2.3v2.2c1.1,0,2.1,0.3,3,0.8V3H27v13.5H14.4
	c0.9,0.8,1.6,1.8,1.9,3h11.5c1.2,0,2.3-1,2.3-2.3V2.3C30,1,29,0,27.8,0z M7.5,15c2.5,0,4.5-2,4.5-4.5S10,6,7.5,6S3,8,3,10.5
	S5,15,7.5,15z M15.4,7.8c-0.4-0.4-1.2-0.4-1.6,0l-0.7,0.7c0.2,0.6,0.4,1.3,0.4,2c0,0.3,0,0.6-0.1,0.9l1.2-1.2l2.6,2.6
	c0.4,0.4,1.2,0.4,1.6,0l3.4-3.4l1.1,1.1c0.5,0.5,1.4,0.2,1.4-0.6V5.8c0-0.3-0.3-0.6-0.6-0.6H20c-0.8,0-1.1,0.9-0.6,1.4l1.1,1.1
	L18,10.4L15.4,7.8z"
        />
      </svg>
    );
  }
}
AppChartUser.propTypes = {
  height: PropTypes.string.isRequired
};

export class AppChartAnalytics extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27 24"
        onClick={this.props.onClick}
      >
        <path
          d="M23.9,4.3c0.3,0.1,0.5,0.2,0.8,0.2c1.2,0,2.3-1,2.3-2.3S26,0,24.8,0s-2.3,1-2.3,2.3c0,0.1,0,0.2,0,0.3l-4.5,3.6
	C17.8,6.1,17.5,6,17.2,6s-0.6,0.1-0.8,0.2L12,2.6c0-0.1,0-0.2,0-0.3C12,1,11,0,9.8,0S7.5,1,7.5,2.3c0,0.2,0,0.4,0.1,0.6L2.8,7.6
	c-0.2,0-0.4-0.1-0.6-0.1C1,7.5,0,8.5,0,9.8S1,12,2.3,12s2.3-1,2.3-2.3c0-0.2,0-0.4-0.1-0.6l4.8-4.8c0.2,0,0.4,0.1,0.6,0.1
	c0.3,0,0.6-0.1,0.8-0.2L15,7.9c0,0.1,0,0.2,0,0.3c0,1.2,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0-0.1,0-0.2,0-0.3L23.9,4.3L23.9,4.3z M18.8,15
	h-3c-0.4,0-0.8,0.3-0.8,0.8v7.5c0,0.4,0.3,0.8,0.8,0.8h3c0.4,0,0.8-0.3,0.8-0.8v-7.5C19.5,15.3,19.2,15,18.8,15z M26.3,9h-3
	c-0.4,0-0.8,0.3-0.8,0.8v13.5c0,0.4,0.3,0.8,0.8,0.8h3c0.4,0,0.8-0.3,0.8-0.8V9.8C27,9.3,26.7,9,26.3,9z M11.3,9h-3
	C7.8,9,7.5,9.3,7.5,9.8v13.5c0,0.4,0.3,0.8,0.8,0.8h3c0.4,0,0.8-0.3,0.8-0.8V9.8C12,9.3,11.7,9,11.3,9z M3.8,16.5h-3
	c-0.4,0-0.8,0.3-0.8,0.8v6C0,23.7,0.3,24,0.8,24h3c0.4,0,0.8-0.3,0.8-0.8v-6C4.5,16.8,4.2,16.5,3.8,16.5z"
        />
      </svg>
    );
  }
}
AppChartAnalytics.propTypes = {
  height: PropTypes.string.isRequired
};

export class LeftArrow extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 85 85"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.6,75.9v-14h24.3c5,0,9.1-4.1,9.1-9.1V32.2c0-5-4.1-9.1-9.1-9.1H51.6v-14c0-8.1-9.8-12.2-15.5-6.4L2.7,36.1
	c-3.6,3.6-3.6,9.3,0,12.9l33.4,33.4C41.8,88,51.6,84,51.6,75.9z M9.1,42.5L42.5,9.1v23.1h33.4v20.5H42.5v23.1L9.1,42.5z"
        />
      </svg>
    );
  }
}
LeftArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class BaselineIcon extends React.Component {
  render() {
    let fillAxis = "#666";
    let fillLine = "#045976";
    let lineStroke = "#0080AB";
    let fillPoints = "#cc7e00";
    if (this.props.color !== undefined) {
      fillAxis = this.props.color;
      fillPoints = this.props.color;
      fillLine = this.props.color;
      lineStroke = this.props.color;
    }
    return (
      <svg
        // fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26"
      >
        <rect fill={fillAxis} width="2.38" height="26" />
        <rect fill={fillAxis} y="23.62" width="26" height="2.38" />
        <path
          fill={fillLine}
          stroke={lineStroke}
          d="M4.2,22.92H3.65l5.47-6.47h15.7v0.45H9.23L4.2,22.92z"
        />
        <ellipse fill={fillPoints} cx="7.2" cy="16.58" rx="3.04" ry="3.04" />
        <ellipse
          fill={fillPoints}
          className="st2"
          cx="14.96"
          cy="16.58"
          rx="3.04"
          ry="3.04"
        />
        <ellipse
          fill={fillPoints}
          className="st2"
          cx="22.72"
          cy="16.58"
          rx="3.04"
          ry="3.04"
        />
      </svg>
    );
  }
}
BaselineIcon.propTypes = {
  height: PropTypes.string.isRequired
};
export class InputSourceIcon extends React.Component {
  render() {
    let mainColor = "#a6a6a6";
    let topDisc = "#cc7e00";
    if (this.props.color !== undefined) {
      mainColor = this.props.color;
      topDisc = this.props.color;
    }
    return (
      <svg
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.75 26"
      >
        <path
          fill={topDisc}
          d="M22.75,3.71v2.32c0,2.05-5.09,3.71-11.38,3.71S0,8.08,0,6.04V3.71C0,1.67,5.09,0,11.38,0S22.75,1.67,22.75,3.71
	z"
        />
        <path
          fill={mainColor}
          d="M0,17.06v5.22C0,24.33,5.09,26,11.38,26s11.38-1.67,11.38-3.71v-5.22c-2.44,1.68-6.92,2.47-11.38,2.47S2.44,18.75,0,17.06z
	 M11.38,11.4C6.92,11.4,2.44,10.62,0,8.94v5.22c0,2.05,5.09,3.71,11.38,3.71s11.38-1.67,11.38-3.71V8.94
	C20.31,10.62,15.83,11.4,11.38,11.4z"
        />
      </svg>
    );
  }
}
InputSourceIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class LiftModelIcon extends React.Component {
  render() {
    let fillAxis = "#666";
    let fillPoints = "#cc7e00";
    if (this.props.color !== undefined) {
      fillAxis = this.props.color;
      fillPoints = this.props.color;
    }
    return (
      <svg
        // fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 26"
      >
        <path
          d="M25.94,23.42H9.48C16.74,20.91,26,13.02,26,1.35h-1.41c0,13.09-12.56,21.91-22.06,22.08V0H0
            v25.39l0,0V26h25.94V23.42z"
          fill={fillAxis}
        />
        <path
          d="M6.93,21.2c1.81,0,3.27-1.46,3.27-3.27s-1.46-3.27-3.27-3.27s-3.27,1.46-3.27,3.27l0,0
            C3.66,19.74,5.12,21.2,6.93,21.2z"
          fill={fillPoints}
        />
        <path
          d="M20.04,8.43c1.81,0,3.27-1.46,3.27-3.27c0-1.81-1.46-3.27-3.27-3.27
          c-1.81,0-3.27,1.46-3.27,3.27C16.77,6.96,18.24,8.43,20.04,8.43z"
          fill={fillPoints}
        />
        <path
          d="M22.4,21.59c1.81,0,3.27-1.46,3.27-3.27s-1.46-3.27-3.27-3.27s-3.27,1.46-3.27,3.27
            S20.59,21.59,22.4,21.59z"
          fill={fillPoints}
        />
        <path
          d="M15.5,17.43c1.81,0,3.27-1.46,3.27-3.27s-1.46-3.27-3.27-3.27s-3.27,1.46-3.27,3.27
            S13.69,17.43,15.5,17.43z"
          fill={fillPoints}
        />
      </svg>
    );
  }
}
LiftModelIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class PromoPlanIcon extends React.Component {
  render() {
    let mainColor = "#666";
    let sound = "#cc7e00";
    if (this.props.color !== undefined) {
      sound = this.props.color;
      mainColor = this.props.color;
    }
    return (
      <svg
        // fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 21.47"
      >
        <path
          fill={mainColor}
          d="M13.07,2.07l-0.45,0.3c-0.25,0.17-0.31,0.5-0.15,0.75l0.25,0.36L1.35,15.75c-0.18-0.17-0.45-0.2-0.66-0.06l-0.45,0.3
		c-0.25,0.17-0.31,0.5-0.15,0.75l3.04,4.49c0.17,0.25,0.5,0.31,0.75,0.15l0.45-0.3c0.21-0.14,0.28-0.41,0.19-0.64L8.26,19
		c0.08,0.18,0.16,0.37,0.27,0.54c1.01,1.49,3.03,1.88,4.52,0.87c1.24-0.84,1.7-2.38,1.24-3.73l5.84-2.25l0.25,0.36
		c0.17,0.25,0.5,0.31,0.75,0.15l0.45-0.3c0.25-0.17,0.31-0.5,0.15-0.75l-7.9-11.67C13.66,1.97,13.32,1.9,13.07,2.07z M12.14,19.07
		c-0.74,0.5-1.76,0.31-2.26-0.44c-0.05-0.07-0.06-0.14-0.1-0.22l2.99-1.15C12.97,17.92,12.74,18.66,12.14,19.07z M3.61,19.05
		L2.3,17.11L13.66,4.87l5.54,8.18L3.61,19.05z"
        />
        <rect
          x="18.57"
          y="4.19"
          transform="matrix(0.8282 -0.5605 0.5605 0.8282 0.8764 13.2271)"
          width="6.88"
          height="1.99"
          fill={sound}
        />
        <rect
          x="16.16"
          y="1.86"
          transform="matrix(-0.5866 0.8099 -0.8099 -0.5866 32.3881 -10.8327)"
          width="5.6"
          height="1.99"
          fill={sound}
        />
        <rect
          x="20.24"
          y="7.89"
          transform="matrix(0.9699 -0.2434 0.2434 0.9699 -1.4697 5.8757)"
          width="5.6"
          height="1.99"
          fill={sound}
        />
      </svg>
    );
  }
}
PromoPlanIcon.propTypes = {
  height: PropTypes.string.isRequired
};
export class ReportSvg extends React.Component {
  render() {
    let viewbox = "0 0 24.7 18";
    if (this.props.viewBox) {
      viewbox = this.props.viewBox;
    }
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        viewBox={viewbox}
        //viewBox="0 0 24.7 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4,18h2.7c0.4,0,0.9-0.4,0.9-0.9V7.3c0-0.4-0.4-0.9-0.9-0.9h-2.7c-0.4,0-0.9,0.4-0.9,0.9v9.8C13.5,17.5,13.9,18,14.4,18z
         M21.1,18h2.7c0.4,0,0.9-0.4,0.9-0.9V0.9c0-0.5-0.4-0.9-0.9-0.9h-2.7c-0.4,0-0.9,0.4-0.9,0.9v16.1C20.2,17.5,20.7,18,21.1,18z
         M0.9,18h2.7c0.4,0,0.9-0.4,0.9-0.9v-4.4c0-0.4-0.4-0.9-0.9-0.9H0.9c-0.4,0-0.9,0.4-0.9,0.9v4.4C0,17.5,0.4,18,0.9,18z M7.6,18h2.7
        c0.4,0,0.9-0.4,0.9-0.9V4.9c0-0.4-0.4-0.8-0.9-0.8H7.6c-0.4,0-0.9,0.4-0.9,0.8v12.1C6.7,17.5,7.2,18,7.6,18z"
        />
      </svg>
    );
  }
}
ReportSvg.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class WarningTriangleIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 21.3"
      >
        <path
          d="M10.4,8.5l0.3,4.7c0,0.3,0.2,0.5,0.5,0.5h1.7c0.3,0,0.5-0.2,0.5-0.5l0.3-4.7
	c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0h-2.3C10.6,8,10.4,8.2,10.4,8.5C10.4,8.5,10.4,8.5,10.4,8.5z M13.7,16c0,1-0.8,1.7-1.8,1.7
	c-1,0-1.7-0.8-1.7-1.8c0-1,0.8-1.7,1.7-1.7C13,14.3,13.7,15,13.7,16C13.7,16,13.7,16,13.7,16z M13.7,1C13.2,0,12-0.3,11,0.3
	c-0.3,0.2-0.6,0.4-0.7,0.7l-10,17.3c-0.6,1-0.2,2.2,0.7,2.7c0.3,0.2,0.6,0.3,1,0.3h20c1.1,0,2-0.9,2-2c0-0.4-0.1-0.7-0.3-1L13.7,1
	L13.7,1z M2.2,19l9.6-16.6c0.1-0.1,0.2-0.2,0.3-0.1c0,0,0.1,0.1,0.1,0.1L21.8,19c0.1,0.1,0,0.3-0.1,0.3c0,0-0.1,0-0.1,0H2.4
	c-0.1,0-0.2-0.1-0.3-0.2C2.2,19,2.2,19,2.2,19z"
        />
      </svg>
    );
  }
}
WarningTriangleIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class DisabledIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M12,0C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.37,12-12h0C24,5.37,18.63,0,12,0Zm6.3,5.7c3.06,3.05,3.48,7.86,1,11.4L6.89,4.7c3.54-2.48,8.35-2.06,11.4,1ZM5.7,18.3c-3.06-3.05-3.48-7.86-1-11.4l12.4,12.4c-3.54,2.48-8.35,2.06-11.4-1Z" />
      </svg>
    );
  }
}
DisabledIcon.propTypes = {
  height: PropTypes.string.isRequired
};

export class XIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M16.55,12l6.82-6.82c.84-.84,.84-2.19,0-3.03l-1.52-1.52c-.84-.84-2.19-.84-3.03,0l-6.82,6.82L5.18,.63C4.34-.21,2.98-.21,2.14,.63L.63,2.14C-.21,2.98-.21,4.34,.63,5.18l6.82,6.82L.63,18.82c-.84,.84-.84,2.19,0,3.03l1.52,1.52c.84,.84,2.2,.84,3.03,0l6.82-6.82,6.82,6.82c.84,.84,2.2,.84,3.03,0l1.52-1.52c.84-.84,.84-2.19,0-3.03l-6.82-6.82Z" />
      </svg>
    );
  }
}
XIcon.propTypes = {
  height: PropTypes.string.isRequired
};
export class DownloadIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        width={this.props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          className={this.props.className}
          d="M8.4,0h3.1c0.5,0,0.9,0.4,0.9,0.9v6.6h3.4c0.7,0,1,0.8,0.6,1.3l-5.9,5.9c-0.3,0.3-0.8,0.3-1.1,0L3.5,8.8
	C3,8.3,3.4,7.5,4.1,7.5h3.4V0.9C7.5,0.4,7.9,0,8.4,0z M20,14.7v4.4c0,0.5-0.4,0.9-0.9,0.9H0.9C0.4,20,0,19.6,0,19.1v-4.4
	c0-0.5,0.4-0.9,0.9-0.9h5.7l1.9,1.9c0.8,0.8,2.1,0.8,2.8,0l1.9-1.9h5.7C19.6,13.8,20,14.2,20,14.7z M15.2,18.1
	c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8S15.2,18.6,15.2,18.1z M17.7,18.1c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8
	s0.4,0.8,0.8,0.8S17.7,18.6,17.7,18.1z"
        />
      </svg>
    );
  }
}
DownloadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export class RefreshIcon extends React.Component {
  render() {
    return (
      <svg
        fill={this.props.color}
        className={`${this.props.className}`}
        style={this.props.style}
        height={this.props.height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M17.21,0.49l0.16,3.23c-1.84-2.17-4.53-3.41-7.38-3.41c-4.73,0-8.67,3.4-9.51,7.88C0.43,8.45,0.6,8.7,0.86,8.74
          c0.03,0.01,0.06,0.01,0.09,0.01h1.92c0.22,0,0.41-0.15,0.46-0.36C4.2,4.7,7.91,2.43,11.6,3.32c1.67,0.4,3.13,1.42,4.09,2.85
          l-3.96-0.19c-0.26-0.01-0.48,0.19-0.49,0.45c0,0.01,0,0.02,0,0.02v1.85c0,0.26,0.21,0.47,0.47,0.47h7.82c0.26,0,0.47-0.21,0.47-0.47
          V0.47C20,0.21,19.79,0,19.53,0h-1.85c-0.26,0-0.47,0.21-0.47,0.47C17.21,0.48,17.21,0.48,17.21,0.49z M9.99,16.88
          c-2.29,0-4.43-1.14-5.7-3.04l3.98,0.19c0.26,0.01,0.48-0.19,0.49-0.45c0-0.01,0-0.02,0-0.02v-1.85c0-0.26-0.21-0.47-0.47-0.47H0.47
          C0.21,11.24,0,11.45,0,11.71v7.83C0,19.79,0.21,20,0.47,20h1.85c0.26,0,0.47-0.21,0.47-0.47c0-0.01,0-0.02,0-0.02l-0.16-3.23
          c1.84,2.16,4.53,3.41,7.37,3.41c4.73,0,8.67-3.4,9.51-7.88c0.05-0.25-0.12-0.5-0.38-0.55c-0.03-0.01-0.06-0.01-0.09-0.01h-1.92
          c-0.22,0-0.41,0.15-0.46,0.36C15.93,14.7,13.17,16.88,9.99,16.88z"
        />
      </svg>
    );
  }
}
RefreshIcon.propTypes = {
  height: PropTypes.string.isRequired
};
