import React, { Component } from "react";
import CreatableSelect from "react-select";
import { connect } from "react-redux";
import { getClients, setClient } from "../../reducers/clientReducer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getGroups,
  addUserGroup,
  addUserGroupSuccess
} from "../../reducers/groupReducer";
//import { resetAllStatus } from "reducers/workspaceReducer";
import {
  getUsers,
  addNewUser,
  inviteUsers,
  inviteSpecifiedUsers,
  deleteUsers,
  addUserToGroup,
  updateUserGroups,
  graphUpdateUserGroups,
  filterUsers,
  clearToasts,
  updateUser
} from "../../reducers/userManagementReducer";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community";
import "./style.css";
import _ from "lodash";
import axios from "axios";
import saveAs from "file-saver";
import { toast } from "react-toastify";
import validator from "validator";

// simple function cellRenderer, just returns back the name of the group
function GroupCellRenderer() {}
GroupCellRenderer.prototype.init = function (params) {
  this.eGui = document.createElement("span");
  var text = "";
  if (params.data.groups.length > 0) {
    text = params.data.groups.map(group => {
      return group.name;
    });
  }
  this.eGui.innerHTML = text.toString();
};
GroupCellRenderer.prototype.getGui = function () {
  return this.eGui;
};

var groupComparator = (item1, item2, node1, node2) => {
  if (node1 === undefined) {
    return item1;
  }
  var a = node1.data.groups[0].name.toLowerCase();
  var b = node2.data.groups[0].name.toLowerCase();
  return a > b ? 1 : a < b ? -1 : 0;
};

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      selectedFile: null,
      loaded: 0,
      client: this.props.clients,
      inputValue: "",
      showAddUserModal: false,
      showDeleteConfirmModal: false,
      showAssignGroupsModal: false,
      showGroupsModal: false,
      firstName: "",
      lastName: "",
      userName: "",
      groups: [],
      selectedUsers: [],
      invitedCheckRan: false,
      uninvited: false,
      columnDefs: [
        {
          headerName: "Username",
          field: "userName",
          checkboxSelection: true,
          sortable: true
        },
        {
          headerName: "First Name",
          field: "firstName",
          editable: true,
          sortable: true
        },
        {
          headerName: "Last Name",
          field: "lastName",
          editable: true,
          sortable: true
        },
        {
          headerName: "Groups",
          field: "group",

          cellRenderer: GroupCellRenderer,
          filter: "agTextColumnFilter",
          comparator: groupComparator,
          filterParams: {
            textCustomComparator: function (filter, value, filterText) {
              var filterTextLoweCase = filterText.toLowerCase();
              var valueLowerCase = value.toString().toLowerCase();
              switch (filter) {
                case "contains":
                  return valueLowerCase.indexOf(filterTextLoweCase) >= 0;
                case "notContains":
                  return valueLowerCase.indexOf(filterTextLoweCase) === -1;
                case "equals":
                  return valueLowerCase === filterTextLoweCase;
                case "notEqual":
                  return valueLowerCase !== filterTextLoweCase;
                case "startsWith":
                  return valueLowerCase.indexOf(filterTextLoweCase) === 0;
                case "endsWith":
                  var index = valueLowerCase.lastIndexOf(filterTextLoweCase);
                  return (
                    index >= 0 &&
                    index === valueLowerCase.length - filterTextLoweCase.length
                  );
                default:
                  // should never happen
                  console.warn("invalid filter type " + filter);
                  return false;
              }
            },

            valueGetter: function getter(params) {
              var text = "";
              if (params.data.groups.length > 0) {
                text = params.data.groups.map(group => {
                  return group.name;
                });
              }
              return text;
            }
          }
        },
        { headerName: "Status", field: "status", sortable: true }
      ]
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.cellEditStopped = this.cellEditStopped.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.addUser = this.addUser.bind(this);
    this.inviteUsers = _.debounce(this.inviteUsers.bind(this), 1000, {
      leading: true,
      trailing: false
    });
    this.deleteUsers = _.debounce(this.deleteUsers.bind(this), 1000, {
      leading: true,
      trailing: false
    });
    this.onGridReady = this.onGridReady.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
    this.showAssignModal = this.showAssignModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.cellValueChanged = this.cellValueChanged.bind(this);
  }

  handleChange = selectedOption => {
    this.props.setClient(selectedOption);

    this.setState({
      selectedClient: selectedOption,
      selectedUsers: [],
      invitedCheckRan: false,
      uninvited: false
    });
    this.props.getUsers(selectedOption.value);
    this.props.getGroups(selectedOption.value);
  };
  componentDidMount() {
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });

      this.props.getUsers(this.props.selectedClient.value);
      this.props.getGroups(this.props.selectedClient.value);
    }
  }

  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }
  componentDidUpdate() {
    if (!this.props.loading && !this.state.invitedCheckRan) {
      let uninvited = this.props.users.filter(user => {
        return user.status === "Uninvited";
      });
      if (uninvited.length > 0) {
        this.setState({ invitedCheckRan: true, uninvited: true });
      }
    }
    if (this.props.inviteToastMessage != null) {
      if (this.props.inviteToastMessage.pass) {
        toast.success(this.props.inviteToastMessage.message);
      } else {
        toast.error(this.props.inviteToastMessage.message);
      }
      this.props.clearToasts();
    }
  }

  updateInputValue(evt, source = null) {
    if (source == null) {
      this.setState({
        inputValue: evt.target.value
      });
    } else {
      this.setState({
        [source]: evt.target.value
      });
    }
  }

  handleClose() {
    this.setState({
      showAddUserModal: false,
      showGroupsModal: false,
      inputValue: "",
      userName: "",
      firstName: "",
      lastName: "",
      groups: [],
      showDeleteConfirmModal: false,
      showAssignGroupsModal: false
    });
  }
  cellEditStopped(event) {
    const { users } = this.props;
    if (event.colDef.field === "lastName") {
      users[event.rowIndex].lastName = event.value;
    } else if (event.colDef.field === "firstName") {
      users[event.rowIndex].firstName = event.value;
    }
  }

  onRowSelected(event, grid = "") {
    if (grid === "main") {
      let selectedUsers = this.state.selectedUsers;
      let runPush = true;
      if (event.node.selected) {
        this.state.selectedUsers.forEach(user => {
          if (user.id === event.data._id) {
            runPush = false;
          }
        });
        if (event.data._id && runPush)
          selectedUsers.push({
            id: event.data._id,
            graphId: event.data.graphId,
            name: event.data.userName,
            groups: event.data.groups,
            status: event.data.status
          });
      } else {
        selectedUsers = selectedUsers.filter(user => {
          return user.id !== event.data._id;
        });
      }
      this.setState({ selectedUsers });
    } else {
      let groups = this.state.groups;
      if (event.node.selected) {
        groups.push(event.data.id);
      } else {
        groups = groups.filter(group => {
          return group !== event.data.id;
        });
      }
      this.setState({ groups });
    }
  }

  addUser() {
    const { userName, lastName, firstName, groups } = this.state;
    if (userName === "") {
      window.alert("You need to specify a User Name");
    } else if (!validator.isEmail(userName)) {
      window.alert("Your User Name is required to be a Email Address");
    } else {
      this.props.addNewUser(this.props.selectedClient.value, {
        userName,
        lastName,
        firstName,
        groups
      });
      // eslint-disable-next-line quotes

      this.setState({ invitedCheckRan: false, uninvited: true });
      this.handleClose();
    }
  }
  updateGroups() {
    const { groups } = this.state;
    let selectedUsers = this.state.selectedUsers;
    let graphUsers = [];
    let nonGraphUsers = [];

    selectedUsers = selectedUsers.map(sUser => {
      sUser.groups = groups;
      return sUser;
    });

    selectedUsers.forEach(sUser => {
      if (sUser.status === "Uninvited") {
        nonGraphUsers.push(sUser);
      } else {
        graphUsers.push(sUser);
      }
    });
    if (nonGraphUsers.length > 0) {
      this.props.updateUserGroups(
        this.props.selectedClient.value,
        nonGraphUsers
      );
    }
    if (graphUsers.length > 0) {
      this.props.graphUpdateUserGroups(
        this.props.selectedClient.value,
        graphUsers
      );
    }
    toast.success("Selected Users updated.");
    this.setState({ selectedUsers: [] });
    this.handleClose();
  }
  deleteUsers() {
    const { selectedUsers } = this.state;
    if (selectedUsers.length > 0) {
      this.props.deleteUsers(this.props.selectedClient.value, selectedUsers);

      this.setState({
        selectedUsers: [],
        invitedCheckRan: false,
        uninvited: false
      });
      this.handleClose();
    }
  }
  async inviteUsers() {
    if (this.props.selectedClient !== null) {
      if (this.state.selectedUsers.length > 0) {
        await this.props.inviteSpecifiedUsers(
          this.props.selectedClient.value,
          this.state.selectedUsers
        );
        // toast.success("Specified Users invited.");
      } else {
        await this.props.inviteUsers(this.props.selectedClient.value);
        // toast.success("All Users invited.");
      }

      this.setState({
        invitedCheckRan: true,
        uninvited: false
      });
    }
  }

  addUserModal() {
    const { showAddUserModal } = this.state;
    return (
      <Modal style={{ height: "80%" }} isOpen={showAddUserModal}>
        <ModalHeader>Add User</ModalHeader>

        <ModalBody>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Username (email address)</div>
              <input
                ref={this.userName}
                value={this.state.userName}
                onChange={evt => this.updateInputValue(evt, "userName")}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>First Name</div>
              <input
                ref={this.firstName}
                value={this.state.firstName}
                onChange={evt => this.updateInputValue(evt, "firstName")}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Last Name</div>
              <input
                ref={this.lastName}
                value={this.state.lastName}
                onChange={evt => this.updateInputValue(evt, "lastName")}
              />
            </div>
          </div>
          <div
            style={{ height: "80%", display: "flex", flexDirection: "column" }}
          >
            <div>Assign User Groups</div>
            <div
              style={{ height: "100%", width: "100%" }}
              className="ag-theme-balham"
            >
              <AgGridReact
                columnDefs={[
                  {
                    headerName: "Group",
                    field: "name",
                    checkboxSelection: true
                  }
                ]}
                rowData={this.props.userGroups}
                rowSelection={"multiple"}
                enableSorting={true}
                enableFilter={true}
                onRowSelected={this.onRowSelected}
                onGridReady={event => this.onGridReady(event)}
              />
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.addUser}>
            Save changes
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }

  deleteConfirmModal() {
    const { showDeleteConfirmModal } = this.state;
    return (
      <Modal isOpen={showDeleteConfirmModal}>
        <ModalHeader>Delete Users</ModalHeader>

        <ModalBody>
          <div style={{ display: "flex" }}>
            Are you sure you want to delete the selected user(s)?
          </div>
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.deleteUsers}>
            Delete
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }

  onGridReady(event, populateChecks = false) {
    var selectedUsers = this.state.selectedUsers;
    if (populateChecks) {
      event.api.forEachNode(node => {
        selectedUsers[0].groups.forEach(group => {
          if (node.data.id === group._id) {
            node.setSelected(true);
            return;
          } else if (node.data.id === group) {
            node.setSelected(true);
            return;
          }
        });
      }, this);
    }
    event.api.sizeColumnsToFit();
  }
  showAssignModal(event) {
    if (this.state.selectedUsers.length >= 1) {
      this.setState({ showAssignGroupsModal: true });
    }
  }

  assignGroupsModal() {
    const { showAssignGroupsModal } = this.state;
    return (
      <Modal style={{ height: "80%" }} isOpen={showAssignGroupsModal}>
        <ModalHeader>Assign User Groups</ModalHeader>

        <ModalBody>
          <div
            style={{ height: "100%", width: "100%" }}
            className="ag-theme-balham"
          >
            <AgGridReact
              columnDefs={[
                {
                  headerName: "Group",
                  field: "name",
                  checkboxSelection: true
                }
              ]}
              rowData={this.props.userGroups}
              rowSelection={"multiple"}
              enableSorting={true}
              enableFilter={true}
              onRowSelected={this.onRowSelected}
              onGridReady={event => this.onGridReady(event, true)}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.updateGroups}>
            Save changes
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
  addGroupModal() {
    const { showGroupsModal } = this.state;
    return (
      <Modal isOpen={showGroupsModal}>
        <ModalHeader>Add User Group</ModalHeader>

        <ModalBody>
          <input
            value={this.state.inputValue}
            onChange={evt => this.updateInputValue(evt)}
          />
        </ModalBody>

        <ModalFooter>
          <Button bsstyle="primary" onClick={this.handleSave}>
            Save changes
          </Button>
          <Button onClick={this.handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
  handleSave() {
    var value = this.state.inputValue;
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/addGroup`,
        {
          client: this.props.selectedClient.value,
          groupName: value
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // eslint-disable-next-line quotes
        toast.success('Group "' + value + '" added.');
        this.props.addUserGroupSuccess(response.data.userGroup);
      })
      .catch(error => {
        // eslint-disable-next-line quotes
        toast.error('Group "' + value + '" Already Exists');
      });

    this.setState({ showGroupsModal: false, inputValue: "" });
  }
  cellValueChanged(item) {
    var user = this.props.searchUsers[item.rowIndex];
    if (item.colDef.field === "firstName") {
      user.firstName = item.newValue;
    } else if (item.colDef.field === "lastName") {
      user.lastName = item.newValue;
    }

    this.props.updateUser(user);
  }
  handleselectedFile = event => {
    event.preventDefault();
    if (
      event.target.files[0].type === "text/csv" ||
      event.target.files[0].type === "application/vnd.ms-excel"
    ) {
      const data = new FormData();
      data.append("file", event.target.files[0]);
      data.append("name", "Bulk Upload");
      data.append("client", this.props.selectedClient.value);

      axios
        .post(
          `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/bulkUserAdd`,
          data,
          {
            headers: {
              ClientToken: localStorage.getItem("clientToken")
            }
          }
        )
        .then(res => {
          this.props.getUsers(this.props.selectedClient.value);
          console.log(res.statusText);
        });
    }
  };

  downloadTemplate() {
    const rows = [
      ["userName", "firstName", "lastName", "groups"],
      ["exampleKenny@example.com", "kenny", "votava", "qa|testing"]
    ];
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += encodeURI(row + "\r\n");
    });

    saveAs(csvContent, "bulkUserAddTemplate.csv");
  }
  handleInputChange() {
    this.props.filterUsers(this.userSearch.value, this.props.users);
  }

  render() {
    const { clients, authType, searchUsers } = this.props;
    const { selectedClient, uninvited } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flex: "1 1 auto"
        }}
      >
        <div className="page-title">User Management</div>
        {this.addUserModal()}
        {this.deleteConfirmModal()}
        {this.assignGroupsModal()}
        {this.addGroupModal()}
        <div
          style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}
          className="ovis-tab-view"
        >
          <div className="client" style={{ paddingBottom: "20px" }}>
            <div className="client-text">Client:</div>
            <CreatableSelect
              key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
              //filterOption={() => true}
              className="dropdown"
              value={
                selectedClient //isClearable
              }
              onChange={this.handleChange}
              onInputChange={this.onInputChange.bind(this)}
              options={clients}
              onCreateOption={
                this.handleCreate //autoLoad={true}
              }
            />
            <div className="spacer" style={{ width: "10%" }} />
            <div className="auth-type">{`Auth Type: ${authType}`}</div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row"
              }}
            >
              {uninvited && (
                <div style={{ marginRight: "10px", marginTop: "4px" }}>
                  You have uninvited users:
                </div>
              )}
              {!this.props.isInviting ? (
                <div
                  style={{ marginLeft: "auto" }}
                  className="ta-button ta-button-primary"
                  onClick={this.inviteUsers}
                >
                  Invite Users
                </div>
              ) : (
                <div
                  style={{ marginLeft: "auto" }}
                  className="ta-button ta-button-primary"
                >
                  Inviting ...
                </div>
              )}
            </div>
          </div>
          <div className="user-group-title-div">
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.userSearch = input)}
              onChange={this.handleInputChange}
              style={{ width: "20%" }}
            />
            <div style={{ height: "30px", display: "flex" }}>
              <div
                onClick={event => {
                  if (this.props.selectedClient !== null) {
                    this.setState({ showAddUserModal: true });
                  }
                }}
                className="ta-button ta-button-primary user-button"
              >
                +Add User
              </div>
              <div style={{ marginLeft: "20px" }}>
                <label htmlFor="files" className="ta-button ta-button-primary">
                  Bulk Upload
                </label>
                <input
                  hidden={true}
                  id="files"
                  ref
                  type="file"
                  disabled={
                    this.props.selectedClient === null ? "disabled" : ""
                  }
                  name="Bulk Upload"
                  onChange={this.handleselectedFile}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  marginRight: "20px"
                }}
                onClick={event => {
                  this.downloadTemplate();
                }}
              >
                .csv template
              </div>
              <div
                onClick={event => {
                  this.showAssignModal(event);
                }}
                className="ta-button ta-button-primary user-button"
              >
                Assign Group
              </div>
              <div
                onClick={event => {
                  if (this.props.selectedClient !== null) {
                    this.setState({ showGroupsModal: true });
                  }
                }}
                className="ta-button ta-button-primary user-button"
              >
                +Add Group
              </div>
              <div
                onClick={event => {
                  if (this.state.selectedUsers.length > 0) {
                    this.setState({ showDeleteConfirmModal: true });
                  }
                }}
                className="ta-button ta-button-primary user-button"
              >
                Delete User
              </div>
            </div>
          </div>
          <div
            className="ag-theme-balham"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto"
            }}
          >
            <AgGridReact
              columnDefs={this.state.columnDefs}
              rowData={searchUsers}
              enableSorting={true}
              enableFilter={true}
              onCellEditingStopped={this.cellEditStopped}
              deltaRowDataMode={false}
              rowSelection={"multiple"}
              onCellValueChanged={event => {
                this.cellValueChanged(event);
              }}
              defaultColDef={{
                resizable: true,
                comparator: function (a, b) {
                  if (typeof a === "string") {
                    return a.localeCompare(b);
                  } else {
                    return a > b ? 1 : a < b ? -1 : 0;
                  }
                }
              }}
              getRowNodeId={data => {
                return data._id;
              }}
              onRowSelected={event => {
                this.onRowSelected(event, "main");
              }}
              components={{ GroupCellRenderer: GroupCellRenderer }}
              onGridReady={event => this.onGridReady(event)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clientReducer.clients,
  users: state.userManagementReducer.users,
  inviteToastMessage: state.userManagementReducer.inviteToastMessage,
  searchUsers: state.userManagementReducer.searchUsers,
  loading: state.userManagementReducer.loading,
  authType: state.groupReducer.authType,
  selectedClient: state.clientReducer.selectedClient,
  userGroups: state.groupReducer.userGroups,
  isInviting: state.userManagementReducer.isInviting
});

const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  getUsers(client) {
    dispatch(getUsers(client));
  },
  getGroups(client) {
    dispatch(getGroups(client));
  },
  addNewUser(client, user) {
    dispatch(addNewUser(client, user));
  },
  addUserToGroup(client, user) {
    dispatch(addUserToGroup(client, user));
  },
  graphUpdateUserGroups(client, user) {
    dispatch(graphUpdateUserGroups(client, user));
  },
  updateUserGroups(client, user) {
    dispatch(updateUserGroups(client, user));
  },
  inviteUsers(client) {
    dispatch(inviteUsers(client));
  },
  inviteSpecifiedUsers(client, users) {
    dispatch(inviteSpecifiedUsers(client, users));
  },
  deleteUsers(client, users) {
    dispatch(deleteUsers(client, users));
  },
  addUserGroup(client, groupName) {
    dispatch(addUserGroup(client, groupName));
  },
  filterUsers(filter, users) {
    dispatch(filterUsers(filter, users));
  },
  addUserGroupSuccess(group) {
    dispatch(addUserGroupSuccess(group));
  },
  clearToasts() {
    dispatch(clearToasts());
  },
  updateUser(user) {
    dispatch(updateUser(user));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
