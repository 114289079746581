import React, { Component } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { Icon } from "react-icons-kit";
import { trash } from "react-icons-kit/iconic/trash";
import { copy } from "react-icons-kit/feather/copy";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { getShareLinks, deleteShareLink } from "../reducers/shareLinkReducer";

import axios from "axios";
// import { clipboard } from "react-icons-kit/iconic/clipboard";
import clipboardCopy from "clipboard-copy";
import "./style.css";

const cellStyle = {
  padding: "5px"
  // height: "30px"
  // border: "1px solid #ccc"
};
const tableContainerStyle = {
  maxHeight: "200px",
  overflowY: "auto"
};
const tableStyle = {
  borderCollapse: "collapse",
  width: "100%"
};
// const FilesCellRenderer = props => {
//   const viewFiles = () => {
//     // Here you can define your navigation function
//     // For example:
//     // props.history.push(`/files/${props.data._id}`);
//   };
//   return (
//     <div>
//       {props.value.length}{" "}
//       <div
//         style={{ cursor: "pointer", color: "rgb(110,177,233)" }}
//         onClick={viewFiles}
//       >
//         View
//       </div>
//     </div>
//   );
// };

const LinkCellRenderer = props => {
  const copyLinkToClipboard = () => {
    clipboardCopy(props.value);
    toast.success("Link Copied");
    // clipboard.writeText(props.value);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden"
      }}
    >
      <div
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {props.value}
      </div>
      <Icon
        icon={copy}
        onClick={copyLinkToClipboard}
        style={{ cursor: "pointer", flexShrink: 0 }} // ensures the icon doesn't shrink if text is too long
      />
    </div>
  );
};

const DeleteCellRenderer = props => {
  // Add an onClick handler if you want to handle clicks

  return (
    <div>
      <Icon
        style={{ cursor: "pointer" }}
        icon={trash}
        onClick={() => {
          props.setDeleting(true);
          props.setShareLink(props.data);
          console.log("Trash clicked!");
        }}
      />
    </div>
  );
};
class ShareLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
      columnDefs: [
        {
          headerName: "Share Link Name",
          field: "shareLinkName",
          editable: false,
          sortable: true,
          filter: true
        },
        {
          headerName: "Shared File",
          field: "name",
          editable: false,
          sortable: true,
          filter: true
        },
        {
          headerName: "Share Link",
          field: "link",
          width: 500,
          cellRenderer: "linkCellRenderer"
        },
        {
          headerName: "Created Date",
          field: "created",
          sortable: true,
          filter: true
        },
        {
          headerName: "Created By",
          field: "createdBy",
          sortable: true,
          filter: true
        },
        {
          headerName: "Downloads",
          field: "downloads"
        },
        {
          headerName: "",
          field: "delete",
          cellRenderer: "deleteCellRenderer",
          cellRendererParams: {
            setDeleting: this.setDeleting,
            setShareLink: this.setShareLink
          }
        }
      ],

      uploadLink: "",

      selectedFiles: [],
      doUpdateFiles: false,
      showDeleteFileModal: false,

      deleteShareLinkConfirm: false,
      shareLink: {},
      shareLinkName: "",
      needShareLinkName: false,
      invalidState: false,
      errorMsg: ""
    };
    this.handleClose = this.handleClose.bind(this);
    this.addShareLink = this.addShareLink.bind(this);
    this.DownloadsCellRenderer = this.DownloadsCellRenderer.bind(this);
    this.fileSizeRenderer = this.fileSizeRenderer.bind(this);

    this.onRowSelected = this.onRowSelected.bind(this);
    this.props.getShareLinks();
    this.deleteHandler = this.deleteHandler.bind(this);
    this.setDeleting = this.setDeleting.bind(this);
    this.renderUploadedFiles = this.renderUploadedFiles.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.setShareLink = this.setShareLink.bind(this);
    this.deleteShareLinkHandler = this.deleteShareLinkHandler.bind(this);
  }
  setDeleting = isDeleting => {
    // console.log("DELETING THIS");
    this.setState({ deleteShareLinkConfirm: isDeleting });
  };
  setShareLink = shareLink => {
    this.setState({ shareLink });
  };

  deleteShareLinkHandler() {
    this.props.deleteShareLink(this.state.shareLink._id);
    this.setState({ deleteShareLinkConfirm: false });
  }

  onRowSelected(event) {
    // console.log("ON ROW SELECTED");
    // console.log(event.node.selected);
    // console.log(event.api.getSelectedRows());
    // debugger;
    this.setState({ selectedFiles: event.api.getSelectedRows() });
  }

  handleClose() {
    this.setState({
      showAddShareLink: false,
      showDeleteShareLinkModal: false,
      deleteShareLinkConfirm: false,
      uploadedFiles: [],
      shareLinkName: "",
      needShareLinkName: false,
      uploadDone: false,
      invalidState: false,
      errorMsg: "",
      uploadingFile: false
    });
  }

  //   generateUploadLink() {
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_OVIS_SERVER}/api/app/setUploadToken`,
  //         {},
  //         {
  //           headers: {
  //             ClientToken: localStorage.getItem("clientToken")
  //           }
  //         }
  //       )
  //       .then(res => {
  //         console.log(res.data);
  //         this.setState({ uploadLink: res.data.token });
  //       });
  //   }
  componentDidMount() {}
  //   componentDidUpdate() {
  //     if (this.state.doUpdateFiles && !this.props.loading) {
  //       //selectedInbox
  //       let selectedInbox =
  //         this.props.shareLinks.find(item => {
  //           return item._id === this.state.selectedInbox._id;
  //         }) || null;
  //       this.setState({ doUpdateFiles: false, selectedInbox });
  //     }
  //   }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.shareLinkName.length > 0 &&
        prevState.shareLinkName !== this.state.shareLinkName) ||
      (prevState.shareLinkName !== this.state.shareLinkName &&
        this.state.uploadedFiles.length === 0)
    ) {
      this.setState({
        needShareLinkName: false,
        errorMsg: ""
      });
    }
  }

  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }

  addShareLink() {
    // this.uploadFiles();
    this.props.getShareLinks();
    this.handleClose();
  }
  uploadFiles() {
    const { uploadedFiles } = this.state;
    const allowedFileTypes = [
      "xlsb",
      "jpg",
      "png",
      "pptx",
      "ppt",
      "pdf",
      "zip",
      "xlsx",
      "txt",
      "bmp",
      "mp4",
      "docx",
      "xlsm",
      "csv",
      "gif",
      "jpeg",
      "xls",
      "pbix"
    ];

    if (uploadedFiles !== null && uploadedFiles.length > 0) {
      this.setState({ uploadingFile: true, uploadDone: false });
      for (const [index, fileData] of uploadedFiles.entries()) {
        const fileExtension = fileData.file.name.split(".").pop();

        // Check if file type is allowed before uploading
        if (!allowedFileTypes.includes(fileExtension)) {
          toast.error("Files of this type may not be uploaded");
          continue;
        }

        var data = new FormData();
        data.append("file", fileData.file);
        data.append("shareLinkName", this.state.shareLinkName);
        data.append("fileName", encodeURIComponent(fileData.file.name));

        axios
          .post(
            `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/shareLinkUpload`,
            data,
            {
              headers: {
                "Content-Type": fileData.file.type,
                ClientToken: localStorage.getItem("clientToken")
              },
              onUploadProgress: progressEvent => {
                const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
                // this.setState({ progress });
                // Update progress for this file.
                this.setState(
                  prevState => {
                    const newUploadFiles = [...prevState.uploadedFiles];
                    newUploadFiles[index].progress = progress;
                    return { uploadedFiles: newUploadFiles };
                  },
                  async () => {
                    const allFilesUploaded = this.state.uploadedFiles.every(
                      file => file.progress === 100
                    );
                    if (allFilesUploaded) {
                      // Perform the desired action
                      console.log("All files uploaded");
                      function sleep(ms) {
                        return new Promise(resolve => setTimeout(resolve, ms));
                      }
                      await sleep(2000);

                      this.setState({ uploadDone: true, uploadFile: false });
                    }
                  }
                );
              }
            }
          )
          .then(async res => {
            // console.log(res);
            if (!res.data.success) {
              toast.error(res.data.message);
              this.setState({
                invalidState: true,
                errorMsg: res.data.message,
                uploadingFile: false
              });
              this.state.uploadedFiles.every(file => (file.progress = 0));
            } else {
              console.log("Loading/Uploading file done");
              console.log(res);
              function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
              }
              await sleep(1000);
              this.props.getFileListNoLoadingImage(true, true);
              let newUploadFiles = this.state.uploadedFiles;
              newUploadFiles = newUploadFiles.map(item => {
                item.progress = 100;
                return item;
              });
              this.setState({
                uploadDone: true,
                uploadFile: false,
                uploadFiles: newUploadFiles,
                invalidState: false
              });
            }
          });
      }
    } else {
      toast.error("No Files");
    }
  }

  fileSizeRenderer(params) {
    if (params.value === undefined || params.value === null) return "";

    // Convert bytes to a more readable format
    const bytes = params.value;
    if (bytes < 1024) return bytes + " B";
    else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + " kB";
    else if (bytes < 1024 * 1024 * 1024)
      return (bytes / (1024 * 1024)).toFixed(1) + " MB";
    return (bytes / (1024 * 1024 * 1024)).toFixed(1) + " GB";
  }
  DownloadsCellRenderer(props) {
    if (props.value) {
      return <div>{props.value} </div>;
    } else {
      return null;
    }
  }
  renderUploadedFiles() {
    return this.state.uploadedFiles.map(item => {
      return (
        <tr>
          <td style={cellStyle}>{item.name}</td>
          {/* <td style={cellStyle}>{item.progress}</td> */}
          <td className="progress-cell" style={cellStyle}>
            <div className="progress-container">
              <div
                className="progress-filler"
                style={{ width: `${item.progress}%` }}
              ></div>
            </div>
            <span className="progress-text">{`${item.progress}%`}</span>
          </td>
        </tr>
      );
    });
  }

  handleselectedFile = event => {
    // const { uploadFilepath } = this.state;
    event.preventDefault();

    //var data = new FormData();

    const newFiles = Array.from(event.target.files).map(file => {
      return { file, progress: 0, status: "", _id: "", name: file.name };
    });

    this.setState({
      uploadedFiles: newFiles
    });
  };
  deleteHandler() {
    const { selectedShareLink } = this.state;

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteShareLinks`,
        {
          shareLink: selectedShareLink._id
        },
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(async res => {
        await this.props.getShareLinks();
        this.setState({ doUpdateFiles: true, showDeleteFileModal: false });
        // console.log(res.data);
        // this.setState({ uploadLink: res.data.token });
      })
      .catch(err => {
        this.setState({ showDeleteFileModal: false });
      });
  }
  updateShareLinkName(evt) {
    this.setState({
      //remove non alphanumeric characters
      shareLinkName: evt.target.value.replace(/[\W_]+/g, "")
    });
  }

  render() {
    const {
      showAddShareLink,

      deleteShareLinkConfirm,
      shareLinkName
    } = this.state;

    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Modal isOpen={deleteShareLinkConfirm}>
          <ModalHeader>Confirm Deletion</ModalHeader>

          <ModalBody>
            <div>
              Are you sure you want to delete the sharelink '{shareLinkName}'?
            </div>
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.deleteShareLinkHandler}>
              Delete
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showAddShareLink}>
          <ModalHeader>Create New Share Link</ModalHeader>

          <ModalBody>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* {this.state.needShareLinkName && (
                <div
                  className="error-div validation-error-div"
                  style={{
                    position: "absolute",
                    display: "flex",
                    width: "93%",
                    padding: "5px",
                    zIndex: "1",
                    flexDirection: "column"
                  }}
                >
                  <div
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ needShareLinkName: false });
                    }}
                  >
                    x
                  </div>
                  Share Link Name Required
                </div>
              )} */}
              <label>Share Link Name</label>
              <div
                style={{
                  fontStyle: "italic",
                  fontSize: "14px",
                  color: "#707070",
                  marginBottom: "6px"
                }}
              >
                Share Link Name is not visible to end users
              </div>
              <input
                id="sharelinkName"
                className="text-input"
                value={this.state.shareLinkName}
                onChange={evt => this.updateShareLinkName(evt)}
                style={{ maxWidth: "80%" }}
                placeholder="Please specify a name for this Share Link"
              ></input>
              {this.state.needShareLinkName && (
                <div
                  // className="error-div validation-error-div"
                  style={{
                    color: "red"
                  }}
                >
                  Please Specify a Share Link Name Before Upload
                </div>
              )}
              {this.state.invalidState && this.state.errorMsg.length > 0 && (
                <div
                  // className="error-div validation-error-div"
                  style={{
                    color: "red"
                  }}
                >
                  {this.state.errorMsg}
                </div>
              )}
              <input
                //hidden={true}
                style={{ cursor: "pointer", marginTop: "20px" }}
                id="files"
                ref
                type="file"
                name="Upload"
                disabled={this.state.uploadingFile}
                onChange={this.handleselectedFile}
                onClick={event => {
                  event.target.value = null;
                }}
              />

              <div style={tableContainerStyle}>
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={cellStyle}>Filename</th>
                      <th style={cellStyle}>Upload %</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderUploadedFiles()}</tbody>
                </table>
              </div>
              <div
                style={{
                  cursor:
                    this.state.uploadedFiles.length === 0 ||
                    this.state.uploadingFile
                      ? "not-allowed"
                      : "pointer"
                }}
              >
                <div
                  onClick={() => {
                    // debugger;
                    if (
                      this.state.uploadedFiles.length > 0 &&
                      this.state.shareLinkName !== ""
                    ) {
                      this.uploadFiles();
                    } else {
                      this.setState({ needShareLinkName: true });
                    }
                  }}
                  className={`ta-button ta-button-primary ${
                    this.state.uploadedFiles.length === 0 ||
                    this.state.uploadingFile
                      ? "disabled"
                      : ""
                  }`}
                  style={{
                    // height: "26px",
                    width: "fit-content",
                    textAlign: "center",
                    pointerEvents:
                      this.state.uploadedFiles.length === 0 ||
                      this.state.uploadingFile
                        ? "none"
                        : "auto"
                    // cursor:
                    //   this.state.uploadedFiles.length === 0
                    //     ? "not-allowed"
                    //     : "pointer"
                  }}
                >
                  Upload
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {/* <Button bsstyle="primary" onClick={this.addShareLink}>
              Save
            </Button> */}
            <div
              style={{
                cursor:
                  this.state.uploadedFiles.length === 0 ||
                  !this.state.shareLinkName ||
                  !this.state.uploadDone ||
                  this.state.invalidState
                    ? "not-allowed"
                    : "pointer"
              }}
            >
              <div
                className={`ta-button ta-button-primary ${
                  this.state.uploadedFiles.length === 0 ||
                  !this.state.shareLinkName ||
                  !this.state.uploadDone ||
                  this.state.invalidState
                    ? "disabled"
                    : ""
                }`}
                style={{
                  width: "fit-content",
                  textAlign: "center",
                  pointerEvents:
                    this.state.uploadedFiles.length === 0 ||
                    !this.state.shareLinkName ||
                    !this.state.uploadDone ||
                    this.state.invalidState
                      ? "none"
                      : "auto"
                }}
                onClick={this.addShareLink}
              >
                Save
              </div>
            </div>
            {/* <Button onClick={this.handleClose}>Cancel</Button> */}
            <div
              className="ta-button ta-button-secondary"
              onClick={this.handleClose}
            >
              Cancel
            </div>
          </ModalFooter>
        </Modal>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "5px"
          }}
        >
          <div className="page-title">File Share Links</div>
          <div
            className="ta-button ta-button-primary"
            onClick={() => {
              this.setState({ showAddShareLink: true });
            }}
          >
            New Share Link
          </div>
        </div>

        <div
          className="ag-theme-balham"
          style={{
            height: "99%",
            width: "100%"
          }}
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.props.shareLinks}
            enableSorting={true}
            enableFilter={true}
            rowSelection={"multiple"}
            rowMultiSelectWithClick={true}
            filter={"agTextColumnFilter"}
            frameworkComponents={{
              linkCellRenderer: LinkCellRenderer,
              deleteCellRenderer: DeleteCellRenderer,
              downloadsCellRenderer: this.DownloadsCellRenderer,
              fileSizeRenderer: this.fileSizeRenderer
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shareLinks: state.shareLinkReducer.shareLinks,

  loading: state.shareLinkReducer.loading
});
const mapDispatchToProps = dispatch => ({
  getShareLinks() {
    dispatch(getShareLinks());
  },
  deleteShareLink(shareLink) {
    dispatch(deleteShareLink(shareLink));
  }
  //   getReportSubscriptions() {
  //     dispatch(getReportSubscriptions());
  //   },
  //   getReportSubscriptionsByClient(client) {
  //     dispatch(getReportSubscriptionsByClient(client));
  //   },
  //   saveReportSubscription(rptSub, client) {
  //     dispatch(saveReportSubscription(rptSub, client));
  //   },
  //   deleteReportSubscription(rptSubId, client) {
  //     dispatch(deleteReportSubscription(rptSubId, client));
  //   }
});
export default connect(mapStateToProps, mapDispatchToProps)(ShareLinks);
