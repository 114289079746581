import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { getClients, setClient } from "../../reducers/clientReducer";
import { Editor } from "@tinymce/tinymce-react";
import "./style.css";
import {
  getBannerInfo,
  saveBannerInfo,
  saveLogo
} from "../../reducers/reportReducer";

import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      siteMessage: {},

      activeEditor1: null,
      activeEditor2: null,
      activeEditor3: null,
      unsavedChanges: false,
      saved: false,
      published: false,
      showIsDirtyModal: false,
      toggleActive: "off",
      bannerLeft: "",
      bannerRight: "",
      bannerMiddle: "",
      bannerLeftWidth: "20%",
      bannerRightWidth: "20%",
      bannerMiddleWidth: "60%",
      prevLeftWidth: "",
      prevMiddleWidth: "",
      prevRightWidth: "",
      prevNumColumns: { label: "3", value: 3 },
      columns: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 }
      ],
      numColumns: { label: "3", value: 3 },
      imgSrc: "",
      loaded: false,
      editorsLoaded: false,
      showContentInRemoveModal: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    this.cancelHandler = this.cancelHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.removeLogoHandler = this.removeLogoHandler.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.uploadCallback = this.uploadCallback.bind(this);
  }
  componentDidUpdate() {
    if (this.props.bannerLoading === false && this.state.loaded === false) {
      let active = "off";
      if (this.props.showBanner) {
        active = "on";
      }
      const {
        bannerLeft,
        bannerRight,
        bannerMiddle,
        logo,
        widths,
        numColumns
      } = this.props;

      let nLogo = logo;
      if (logo === undefined) {
        nLogo = "";
      }
      let leftWidth = widths.bannerLeftWidth;
      let middleWidth = widths.bannerMiddleWidth;
      let rightWidth = widths.bannerRightWidth;

      if (widths.bannerLeftWidth === "") {
        leftWidth = "20%";
      }
      if (widths.bannerMiddleWidth === "") {
        middleWidth = "60%";
      }
      if (widths.bannerRightWidth === "") {
        rightWidth = "20%";
      }

      this.setState({
        bannerLeft,
        bannerRight,
        bannerMiddle,
        numColumns: { value: numColumns, label: String(numColumns) },
        bannerLeftWidth: leftWidth,
        bannerMiddleWidth: middleWidth,
        bannerRightWidth: rightWidth,
        prevLeftWidth: leftWidth,
        prevMiddleWidth: middleWidth,
        prevRightWidth: rightWidth,
        prevNumColumns: { value: numColumns, label: String(numColumns) },
        loaded: true,
        imgSrc: nLogo,
        toggleActive: active
      });
    }
  }

  toggleChange() {
    // const newSettings = {
    //   navContentPaneEnabled: true,
    //   filterPaneEnabled: this.props.curRptConfig.showFilterPane
    // };

    if (this.state.loaded) {
      if (this.state.toggleActive === "on") {
        this.setState({ toggleActive: "off", unsavedChanges: true });

        //this.component.updateSettings(newSettings);
      } else {
        this.setState({ toggleActive: "on", unsavedChanges: true });
      }
    }
  }

  handleChange = selectedOption => {
    this.props.setClient(selectedOption);
    this.props.getBannerInfo(selectedOption.value);
    this.setState({
      selectedClient: selectedOption,
      toggleActive: "off",
      loaded: false
    });
  };
  componentDidMount() {
    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
      this.props.getBannerInfo(this.props.selectedClient.value);
    }
  }
  handleNumColumnsChange = selectedOption => {
    const { numColumns, bannerRight, bannerMiddle, bannerLeft } = this.state;
    let runChange = true;
    var nBL = "",
      nBM = "";

    if (selectedOption.value < numColumns.value) {
      if (selectedOption.value === 1) {
        if (bannerLeft === "" && bannerRight === "") {
          nBL = bannerMiddle;
          this.setState({ bannerLeft: nBL, bannerMiddle: "", bannerRight: "" });
        } else if (bannerLeft === "" && bannerMiddle === "") {
          nBL = bannerRight;
          this.setState({ bannerLeft: nBL, bannerMiddle: "", bannerRight: "" });
        } else if (bannerRight === "" && bannerMiddle === "") {
        } else {
          runChange = false;
          this.setState({ showContentInRemoveModal: true });
        }
      } else if (selectedOption.value === 2) {
        if (bannerRight === "" || bannerLeft === "" || bannerMiddle === "") {
          if (bannerMiddle === "") {
            let nBM = bannerRight;
            this.setState({ bannerMiddle: nBM, bannerRight: "" });
          } else if (bannerLeft === "") {
            nBL = bannerMiddle;
            nBM = bannerRight;
            this.setState({
              bannerLeft: nBL,
              bannerMiddle: nBM,
              bannerRight: ""
            });
          }
        } else {
          runChange = false;
          this.setState({ showContentInRemoveModal: true });
        }
      }
    }

    if (runChange) {
      if (selectedOption.value === 1) {
        this.setState({
          numColumns: selectedOption,
          bannerLeftWidth: "100%",
          unsavedChanges: true
        });
      } else if (selectedOption.value === 2) {
        this.setState({
          numColumns: selectedOption,
          bannerLeftWidth: "50%",
          bannerMiddleWidth: "50%",
          unsavedChanges: true
        });
      } else {
        this.setState({
          numColumns: selectedOption,
          bannerLeftWidth: "20%",
          bannerMiddleWidth: "60%",
          bannerRightWidth: "20%",
          unsavedChanges: true
        });
      }
    }
  };
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }

  uploadCallback(blobInfo, progress) {
    return new Promise((resolve, reject) => {
      let file = blobInfo.blob;

      //const xhr = new XMLHttpRequest();
      console.log(file);
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadImage`
      );
      xhr.setRequestHeader("ClientToken", localStorage.getItem("clientToken"));
      var data = new FormData();
      data.append("client", this.props.selectedClient.value);
      data.append("image", blobInfo.blob());
      data.size = file.size;
      xhr.send(data);
      xhr.upload.onprogress = e => {
        progress((e.loaded / e.total) * 100);
      };
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);

        resolve(response.data.link);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
        return;
      });
    });
  }

  handleselectedFile = event => {
    event.preventDefault();

    const data = new FormData();
    data.append("image", event.target.files[0]);
    data.append("name", "Upload");
    data.append("client", this.props.selectedClient.value);

    axios
      .post(`${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadImage`, data, {
        headers: {
          ClientToken: localStorage.getItem("clientToken")
        }
      })
      .then(res => {
        this.setState({ imgSrc: res.data.data.link });
        let sasRm = res.data.data.link.split("?")[0];
        this.props.saveLogo(this.props.selectedClient.value, sasRm);
        console.log(res.statusText);
      });
  };
  handleEditorChange(editor, e) {
    if (
      e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, "") !==
      this.state.siteMessage.html
    ) {
      let unsavedContent = e.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, "");
      if (editor === 1) {
        this.setState({
          unsavedChanges: true,
          bannerLeft: unsavedContent
        });
      } else if (editor === 2) {
        this.setState({
          unsavedChanges: true,
          bannerMiddle: unsavedContent
        });
      } else {
        this.setState({
          unsavedChanges: true,
          bannerRight: unsavedContent
        });
      }
    }
  }
  handleWidthChange(target, event) {
    let value = event.currentTarget.value;

    if (target === 1) {
      this.setState({
        bannerLeftWidth: value,
        unsavedChanges: true
      });
    } else if (target === 2) {
      this.setState({
        bannerMiddleWidth: value,
        unsavedChanges: true
      });
    } else {
      this.setState({
        bannerRightWidth: value,
        unsavedChanges: true
      });
    }
  }
  removeLogoHandler() {
    this.props.saveLogo(this.state.selectedClient.value, "");
    this.setState({ imgSrc: "" });
  }
  renderColumnWidths() {
    const { bannerLeftWidth, bannerMiddleWidth, bannerRightWidth } = this.state;

    if (this.state.numColumns.value === 1) {
      return (
        <div style={{}}>
          <span style={{ marginLeft: "10px", marginRight: "4px" }}>
            Column 1 width:
          </span>
          <input
            onChange={e => {
              this.handleWidthChange(1, e);
            }}
            value={bannerLeftWidth}
            className="column-width-input"
          />
        </div>
      );
    } else if (this.state.numColumns.value === 2) {
      return (
        <div style={{}}>
          <span style={{ marginLeft: "10px", marginRight: "4px" }}>
            Column 1 width:
          </span>
          <input
            onChange={e => {
              this.handleWidthChange(1, e);
            }}
            value={bannerLeftWidth}
            className="column-width-input"
          />
          <span style={{ marginLeft: "10px", marginRight: "4px" }}>
            Column 2 width:
          </span>
          <input
            onChange={e => {
              this.handleWidthChange(2, e);
            }}
            value={bannerMiddleWidth}
            className="column-width-input"
          />
        </div>
      );
    } else {
      return (
        <div>
          <span style={{ marginLeft: "10px", marginRight: "4px" }}>
            Column 1 width:
          </span>
          <input
            onChange={e => {
              this.handleWidthChange(1, e);
            }}
            value={bannerLeftWidth}
            className="column-width-input"
          />
          <span style={{ marginLeft: "10px", marginRight: "4px" }}>
            Column 2 width:
          </span>
          <input
            onChange={e => {
              this.handleWidthChange(2, e);
            }}
            value={bannerMiddleWidth}
            className="column-width-input"
          />
          <span style={{ marginLeft: "10px", marginRight: "4px" }}>
            Column 3 width:
          </span>
          <input
            onChange={e => {
              this.handleWidthChange(3, e);
            }}
            value={bannerRightWidth}
            className="column-width-input"
          />
        </div>
      );
    }
  }
  renderEditors() {
    const { bannerLeftWidth, bannerMiddleWidth, bannerRightWidth } = this.state;
    let blw = bannerLeftWidth;
    let bmw = bannerMiddleWidth;
    let brw = bannerRightWidth;
    if (!blw.endsWith("%")) {
      blw = blw + "%";
    }
    if (!bmw.endsWith("%")) {
      bmw = bmw + "%";
    }
    if (!brw.endsWith("%")) {
      brw = brw + "%";
    }
    if (this.props.bannerLoading === false) {
      if (this.state.numColumns.value === 1) {
        return (
          <Editor
            //disabled={selectedReport === ""}
            ref={editor => {
              this.editor = editor;
            }}
            selector=".tinymce-body"
            apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
            id={"tinyMCE1"}
            onEditorChange={e => this.handleEditorChange(1, e)}
            value={this.state.bannerLeft}
            init={{
              setup: editor => {
                this.setState({ activeEditor1: editor });
              },
              inline: true,
              // images_upload_url: "postAcceptor.php",
              images_upload_handler: this.uploadCallback,
              plugins:
                "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
              menubar: false,
              toolbar:
                "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table",
              powerpaste_word_import: "clean",
              powerpaste_html_import: "clean",
              content_css: [
                "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i"
              ]
            }}
          />
        );
      } else if (this.state.numColumns.value === 2) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              width: "100%"
            }}
          >
            <div style={{ width: blw }}>
              <Editor
                //disabled={selectedReport === ""}
                ref={editor => {
                  this.editor21 = editor;
                }}
                selector=".tinymce-body21"
                apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
                id={"tinyMCE21"}
                onEditorChange={e => this.handleEditorChange(1, e)}
                value={this.state.bannerLeft}
                init={{
                  setup: editor => {
                    this.setState({ activeEditor1: editor });
                  },
                  width: blw,
                  inline: true,
                  // images_upload_url: "postAcceptor.php",
                  images_upload_handler: this.uploadCallback,
                  plugins:
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                  menubar: false,
                  toolbar:
                    "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table",
                  powerpaste_word_import: "clean",
                  powerpaste_html_import: "clean",
                  content_css: [
                    "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i"
                  ]
                }}
              />
            </div>
            <div style={{ width: bmw }}>
              <Editor
                //disabled={selectedReport === ""}
                ref={editor => {
                  this.editor22 = editor;
                }}
                selector=".tinymce-body22"
                apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
                id={"tinyMCE22"}
                onEditorChange={e => this.handleEditorChange(2, e)}
                value={this.state.bannerMiddle}
                init={{
                  setup: editor => {
                    this.setState({ activeEditor2: editor });
                  },
                  width: bmw,
                  inline: true,
                  // images_upload_url: "postAcceptor.php",
                  images_upload_handler: this.uploadCallback,
                  plugins:
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                  menubar: false,
                  toolbar:
                    "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table",
                  powerpaste_word_import: "clean",
                  powerpaste_html_import: "clean",
                  content_css: [
                    "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i"
                  ]
                }}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                width: "100%"
              }}
            >
              <div style={{ width: blw }}>
                <Editor
                  //disabled={selectedReport === ""}
                  ref={editor => {
                    this.editor31 = editor;
                  }}
                  selector=".tinymce-body31"
                  apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
                  id={"tinyMCE31"}
                  onEditorChange={e => this.handleEditorChange(1, e)}
                  value={this.state.bannerLeft}
                  init={{
                    setup: editor => {
                      this.setState({ activeEditor1: editor });
                    },
                    width: blw,
                    inline: true,
                    // images_upload_url: "postAcceptor.php",
                    images_upload_handler: this.uploadCallback,
                    plugins:
                      "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                    menubar: false,
                    toolbar:
                      "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table",
                    powerpaste_word_import: "clean",
                    powerpaste_html_import: "clean",
                    content_css: [
                      "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i"
                    ]
                  }}
                />
              </div>
              <div style={{ width: bmw }}>
                <Editor
                  //disabled={selectedReport === ""}
                  ref={editor => {
                    this.editor32 = editor;
                  }}
                  selector=".tinymce-body32"
                  apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
                  id={"tinyMCE32"}
                  onEditorChange={e => this.handleEditorChange(2, e)}
                  value={this.state.bannerMiddle}
                  init={{
                    setup: editor => {
                      this.setState({ activeEditor2: editor });
                    },
                    inline: true,
                    width: bmw,
                    // images_upload_url: "postAcceptor.php",
                    images_upload_handler: this.uploadCallback,
                    plugins:
                      "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                    menubar: false,
                    toolbar:
                      "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table",
                    powerpaste_word_import: "clean",
                    powerpaste_html_import: "clean",
                    content_css: [
                      "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i"
                    ]
                  }}
                />
              </div>
              <div style={{ width: brw }}>
                <Editor
                  //disabled={selectedReport === ""}
                  ref={editor => {
                    this.editor33 = editor;
                  }}
                  selector=".tinymce-body33"
                  apiKey="pvb1gt41v3ao2iqre7v6pexbfzkpwahqb07bna8kku53ydbt"
                  id={"tinyMCE33"}
                  onEditorChange={e => this.handleEditorChange(3, e)}
                  value={this.state.bannerRight}
                  init={{
                    setup: editor => {
                      this.setState({ activeEditor3: editor });
                    },
                    inline: true,
                    width: brw,
                    // images_upload_url: "postAcceptor.php",
                    images_upload_handler: this.uploadCallback,
                    plugins:
                      "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
                    menubar: false,
                    toolbar:
                      "insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image code table",
                    powerpaste_word_import: "clean",
                    powerpaste_html_import: "clean",
                    content_css: [
                      "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i"
                    ]
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <div>Select A Client to Proceed</div>;
    }
  }
  count(main_str, sub_str) {
    main_str += "";
    sub_str += "";

    if (sub_str.length <= 0) {
      return main_str.length + 1;
    }

    let subStr = sub_str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    return (main_str.match(new RegExp(subStr, "gi")) || []).length;
  }
  saveHandler(publish) {
    const {
      bannerLeft,
      bannerLeftWidth,
      bannerMiddle,
      bannerMiddleWidth,
      bannerRight,
      bannerRightWidth,
      numColumns
    } = this.state;

    let blw = bannerLeftWidth;
    let bmw = bannerMiddleWidth;
    let brw = bannerRightWidth;
    if (!blw.endsWith("%")) {
      blw = blw + "%";
    }
    if (!bmw.endsWith("%")) {
      bmw = bmw + "%";
    }
    if (!brw.endsWith("%")) {
      brw = brw + "%";
    }

    let toSaveContent =
      "<div id='1' style='width: " + blw + "'>" + bannerLeft + "</div>";
    if (bannerMiddle !== "" && numColumns.value >= 2) {
      toSaveContent +=
        "<div id='2' style='width: " + bmw + "'>" + bannerMiddle + "</div>";
    }
    if (bannerRight !== "" && numColumns.value >= 3) {
      toSaveContent +=
        "<div id='3' style='width: " + brw + "'>" + bannerRight + "</div>";
    }
    //Remove the SAS token from the image before saving it
    let rmSAS = toSaveContent;
    let rmCount = this.count(
      toSaveContent,
      "src='https://tabsportal.blob.core.windows.net"
    );
    let x = 1;

    while (x <= rmCount) {
      let remove = rmSAS
        .split("src='https://tabsportal.blob.core.windows.net")
        // eslint-disable-next-line no-unexpected-multiline
        [x].split("'")[0]
        .split("?")[1];
      rmSAS = rmSAS.replace("?" + remove, "");
      x = x + 1;
    }

    toast.success("Saving Landing Page Content ...");
    let active = false;
    if (this.state.toggleActive === "on") {
      active = true;
    }
    this.props.saveBannerInfo(this.state.selectedClient.value, rmSAS, active);
    this.setState({
      unsavedChanges: false
    });
  }

  cancelHandler(e) {
    const { prevLeftWidth, prevMiddleWidth, prevRightWidth, prevNumColumns } =
      this.state;
    const { bannerLeft, bannerMiddle, bannerRight, showBanner } = this.props;
    let active = "off";
    if (showBanner) {
      active = "on";
    }
    if (prevNumColumns.value === 1) {
      this.state.activeEditor1.setContent(bannerLeft);
    } else if (prevNumColumns.value === 2) {
      this.state.activeEditor1.setContent(bannerLeft);
      this.state.activeEditor2.setContent(bannerMiddle);
    } else {
      this.state.activeEditor1.setContent(bannerLeft);
      this.state.activeEditor2.setContent(bannerMiddle);
      this.state.activeEditor3.setContent(bannerRight);
    }
    this.setState({
      showIsDirtyModal: false,
      unsavedChanges: false,
      toggleActive: active,
      bannerLeftWidth: prevLeftWidth,
      bannerMiddleWidth: prevMiddleWidth,
      bannerRightWidth: prevRightWidth,
      numColumns: prevNumColumns
    });
  }
  handleClose() {
    this.setState({ showIsDirtyModal: false, showContentInRemoveModal: false });
  }

  render() {
    const { clients } = this.props;
    // let clientsAndAll = [];
    // clientsAndAll.push({ label: "All Clients", value: "All Clients" });
    // clients.forEach(client => {
    //   clientsAndAll.push(client);
    // });

    const { selectedClient, showIsDirtyModal, showContentInRemoveModal } =
      this.state;
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <div style={{ height: "100%" }}>
            <div>
              <Modal isOpen={showIsDirtyModal}>
                <ModalHeader>Unsaved Changes</ModalHeader>

                <ModalBody>
                  You have unsaved changes are you sure you want to discard your
                  edits?
                </ModalBody>

                <ModalFooter>
                  <Button bsstyle="primary" onClick={this.cancelHandler}>
                    Ok
                  </Button>
                  <Button onClick={this.handleClose}>Cancel</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={showContentInRemoveModal}>
                <ModalHeader>All Columns Have Content!</ModalHeader>

                <ModalBody>
                  All columns contain content. Remove content from at least one
                  column to reduce total columns
                </ModalBody>

                <ModalFooter>
                  <Button bsstyle="primary" onClick={this.handleClose}>
                    Ok
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <div
              className="ovis-tab-view"
              style={{
                overflow: "hidden",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{}}>
                <div>
                  <div className="page-title">Custom Landing Page Content</div>
                  <div>
                    Upload a client logo and create a custom header for the
                    landing page
                  </div>
                </div>
                <div className="client" style={{ marginTop: "10px" }}>
                  <div className="client-text">Client:</div>
                  <Select
                    key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                    //filterOption={() => true}
                    className="dropdown"
                    value={
                      selectedClient //isClearable
                    }
                    onChange={this.handleChange}
                    onInputChange={this.onInputChange.bind(this)}
                    options={clients}
                    onCreateOption={
                      this.handleCreate //autoLoad={true}
                    }
                  />

                  <div className="spacer" style={{ width: "10%" }} />
                  <div className="action-buttons">
                    <div
                      className={
                        this.state.unsavedChanges
                          ? "ta-button ta-button-primary"
                          : "ta-button ta-button-disabled"
                      }
                      onClick={() => {
                        if (this.state.unsavedChanges) {
                          this.saveHandler(false);
                        }
                      }}
                    >
                      Save
                    </div>

                    <div
                      className={
                        this.state.unsavedChanges
                          ? "ta-button ta-button-secondary"
                          : "ta-button ta-button-disabled"
                      }
                      onClick={() =>
                        this.state.unsavedChanges
                          ? this.setState({ showIsDirtyModal: true })
                          : null
                      }
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  paddingTop: "20px",
                  overflow: "hidden"
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #CCC",
                      paddingBottom: "16px"
                    }}
                  >
                    <div style={{ marginRight: "4px" }}>
                      Display on Client Site:
                    </div>
                    <ToggleButtonGroup
                      className="toggle-button-group"
                      value={this.state.toggleActive}
                      onChange={this.toggleChange}
                      exclusive
                    >
                      <ToggleButton
                        classes={{
                          selected: "button-override",
                          root: "button-override-root"
                        }}
                        className="toggle-button"
                        value="on"
                      >
                        <div>On</div>
                      </ToggleButton>
                      <ToggleButton
                        classes={{ selected: "button-override" }}
                        className="toggle-button"
                        value="off"
                      >
                        <div>Off</div>
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <div style={{ marginLeft: "10px" }}>
                      Number of Content Columns:
                    </div>
                    <Select
                      className="dropdown"
                      options={this.state.columns}
                      onChange={this.handleNumColumnsChange}
                      value={this.state.numColumns}
                    />
                    {this.renderColumnWidths()}
                  </div>
                  {/* Content Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                      flex: "1 1 auto"
                    }}
                  >
                    <div className="tinyMCE-container-editors">
                      <div style={{ height: "100%", width: "100%" }}>
                        {this.renderEditors()}
                      </div>
                    </div>
                    {/* <div
                      style={{
                        borderTop: "1px solid gray",
                        width: "100%",
                        marginTop: "30px"
                      }}
                    /> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "40px",
                        width: "300px"
                      }}
                    >
                      <div
                        className="section-heading"
                        style={{ marginRight: "10px" }}
                      >
                        Client Logo
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="instruction-text">
                          Upload a client logo (displays in upper right of
                          portal)
                        </div>
                        <div
                          className="instruction-text"
                          style={{ marginBottom: "10px" }}
                        >
                          Best image height is 26 pixels
                        </div>
                        <div
                          style={{
                            height: "40px",
                            border: "1px solid #CCC",
                            marginBottom: "10px",
                            width: "fit-content"
                          }}
                        >
                          {this.state.imgSrc !== "" && (
                            <img
                              style={{ height: "35px", width: "100px'" }}
                              src={this.state.imgSrc}
                              alt="logo"
                            />
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          <label
                            style={{
                              marginRight: "20px",
                              border: "1px solid gray",
                              padding: "2px",
                              cursor: "pointer"
                            }}
                            htmlFor="upload"
                          >
                            Upload
                          </label>
                          <input
                            hidden={true}
                            id="upload"
                            ref
                            type="file"
                            disabled={
                              this.props.selectedClient === null
                                ? "disabled"
                                : ""
                            }
                            name="Upload"
                            onChange={this.handleselectedFile}
                            onClick={event => {
                              event.target.value = null;
                            }}
                          />
                          <div
                            style={{
                              border: "1px solid gray",
                              padding: "2px",
                              cursor: "pointer",
                              height: "30px"
                            }}
                            onClick={this.removeLogoHandler}
                          >
                            Remove
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient,
  bannerLeft: state.reportReducer.bannerLeft,
  bannerRight: state.reportReducer.bannerRight,
  bannerMiddle: state.reportReducer.bannerMiddle,
  numColumns: state.reportReducer.numColumns,
  logo: state.reportReducer.logo,
  bannerLoading: state.reportReducer.bannerLoading,
  showBanner: state.reportReducer.showBanner,
  widths: state.reportReducer.widths
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  getBannerInfo(client) {
    dispatch(getBannerInfo(client));
  },
  saveBannerInfo(client, content, showBanner) {
    dispatch(saveBannerInfo(client, content, showBanner));
  },
  saveLogo(client, source) {
    dispatch(saveLogo(client, source));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
