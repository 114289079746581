import React, { Component } from "react";
import CreatableSelect from "react-select";
import { connect } from "react-redux";
import AppliedWorkspacesPanel from "../../components/appliedWorkspacesPanel";
import Workspaces from "../../components/workspaces";
import UserGroups from "../../components/userGroups";
import {
  getClients,
  setClient,
  addNewClient
} from "../../reducers/clientReducer";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  getGroups,
  saveGroup,
  cancelGroupChanges,
  setUnsavedGroup,
  setModelRoles,
  addModelRole,
  removeModelRole
} from "../../reducers/groupReducer";
import {
  resetAllStatus,
  getAllWorkspacesForClient,
  setWorkspaces
} from "../../reducers/workspaceReducer";
import "./style/style.css";
import validator from "validator";
import { toast } from "react-toastify";

import cloneDeep from "lodash";
import PBIRole from "./pbiRole";

class WorkspaceMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      showAddClientModal: false,
      inputValue: "",
      client: this.props.clients,
      showDeleteRoleConfirm: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.cancelHandler = this.cancelHandler.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addClient = this.addClient.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.removePbiRoleHandler = this.removePbiRoleHandler.bind(this);
    this.renderPbiRoles = this.renderPbiRoles.bind(this);
    this.showDeleteRoleModal = this.showDeleteRoleModal.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
  }

  handleChange = selectedOption => {
    this.props.setClient(selectedOption);
    this.setState({ selectedClient: selectedOption });
    this.props.getGroups(selectedOption.value);
    this.props.getAllWorkspacesForClient(selectedOption.value);
    this.props.setWorkspaces([]);
  };
  saveHandler() {
    const { selectedClient, userGroups } = this.props;
    let saveGroups = userGroups.filter(group => {
      return group.unsavedChanges === true;
    });
    this.props.saveGroup(selectedClient.value, saveGroups);
    // eslint-disable-next-line quotes
    toast.success('Client "' + selectedClient.value + '" saved successfully.');
    this.props.getGroups(this.state.selectedClient.value);
  }
  cancelHandler() {
    this.props.getGroups(this.state.selectedClient.value);
    this.props.getAllWorkspacesForClient(this.state.selectedClient.value);
    // this.props.getWorkspaces(
    //   this.state.selectedClient.value,
    //   this.props.selectedGroup
    // );
    this.props.cancelGroupChanges();
  }
  handleClose() {
    this.setState({
      showAddClientModal: false,
      showDeleteRoleConfirm: false,
      inputValue: ""
    });
  }
  addClient() {
    let clientName = this.state.inputValue;
    if (clientName === "") {
      window.alert("You need to specify a Client Name");
    } else if (!validator.isLength(clientName, { min: 3, max: 63 })) {
      window.alert(
        "Client Name must be at least 3 characters and not more than 63"
      );
    } else {
      this.props.addNewClient(clientName);
      this.setState({ showAddClientModal: false, inputValue: "" });
      // eslint-disable-next-line quotes
      toast.success('Client "' + clientName + '" added.');
    }
  }
  componentDidMount() {
    this.props.getClients();

    if (this.props.selectedClient !== null) {
      this.setState({
        selectedClient: {
          label: this.props.selectedClient.label,
          value: this.props.selectedClient.value
        }
      });
      this.props.getGroups(this.props.selectedClient.value);
      this.props.getAllWorkspacesForClient(this.props.selectedClient.value);
    }
  }
  showDeleteRoleModal(rmPbiRole) {
    this.setState({ rmRole: rmPbiRole, showDeleteRoleConfirm: true });
  }
  removePbiRoleHandler() {
    const { rmRole } = this.state;
    let pbiRoles = this.props.modelRoles;
    let newPbiRoles = pbiRoles.filter(domItem => {
      return domItem !== rmRole;
    });
    this.props.setModelRoles(newPbiRoles);

    let rmId = rmRole._id;
    if (rmId === undefined) {
      rmId = rmRole.id;
    }
    this.props.removeModelRole(
      this.props.selectedClient.value,
      this.props.selectedGroup,
      rmId
    );
    this.handleClose();
    //this.props.setUnsavedGroup(this.props.selectedGroup, ws, newPbiRoles);
    //this.props.getGroups(this.props.selectedClient.value);
  }
  inputChangeHandler(evt, ws) {
    // const { modelRoles } = this.props;
    if (evt.key === "Enter") {
      //let newPbiRoles = modelRoles;
      //newPbiRoles.push(this.pbiRole.value);

      //this.props.setModelRoles(newPbiRoles);
      this.props.addModelRole(
        this.props.selectedClient.value,
        this.props.selectedGroup,
        this.pbiRole.value
      );
      //this.props.setUnsavedGroup(this.props.selectedGroup, ws, newPbiRoles);
      this.pbiRole.value = "";
    }
  }
  checkboxChangeHandler(modelRole, ws) {
    let newRoles = cloneDeep(this.props.modelRoles).value();

    newRoles = newRoles.map(role => {
      if (modelRole.id === role.id) {
        role.checked = !modelRole.checked;
      }
      return role;
    });
    this.props.setUnsavedGroup(this.props.selectedGroup, ws, newRoles);
  }
  renderPbiRoles(ws) {
    let pbiRoles = this.props.modelRoles;

    var pbiRolesEl = [];
    if (pbiRoles !== undefined) {
      pbiRolesEl = pbiRoles.map((domItem, idx) => {
        return (
          <PBIRole
            key={idx}
            checkboxChangeHandler={this.checkboxChangeHandler}
            showDeleteRoleModal={this.showDeleteRoleModal}
            role={domItem}
            ws={ws}
            index={idx}
          />
        );
      }, this);
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ fontSize: "16px", fontWeight: "500", marginBottom: "10px" }}
        >
          Model Roles
        </div>
        <input
          style={{ marginBottom: "10px" }}
          onKeyPress={evt => {
            this.inputChangeHandler(evt, ws);
          }}
          //disabled={selectedReport === ""}
          ref={input => (this.pbiRole = input)}
          //value={addDomain}
        />
        <div
          style={{
            border: "1px solid #CCC",
            padding: "4px",
            overflow: "auto",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {pbiRolesEl}
        </div>
      </div>
    );
  }
  renderTab() {
    const MESSAGE =
      "Choose the group on the left and then select which workspaces should be associated with it";
    let gIdx = -1;
    this.props.userGroups.forEach((group, idx) => {
      if (group.id === this.props.selectedGroup) {
        gIdx = idx;
      }
    });

    const searchWorkspaces = this.props.searchGroups[gIdx]?.workspaces ?? "";
    const userWorkspaces = this.props.userGroups[gIdx]?.workspaces ?? "";
    return (
      <div
        style={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}
      >
        <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          {MESSAGE}
        </div>
        <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
          <UserGroups />
          {gIdx !== -1 ? (
            <div
              style={{
                marginLeft: "40px",
                display: "flex",
                flexDirection: "row",
                flex: "1 0 auto"
              }}
            >
              <Workspaces
                searchWorkspaces={searchWorkspaces}
                workspaces={userWorkspaces}
              />

              {/* <div className="spacer" style={{ width: "40px" }} /> */}
              {/* <div
                style={{
                  border: "1px solid #CCC",
                  padding: "4px",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column"
                }}
              > */}
              <div style={{ marginLeft: "40px" }}>
                {this.renderPbiRoles(this.props.userGroups[gIdx].workspaces)}
              </div>
              {/* </div> */}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: "40px"
              }}
            >
              <Workspaces workspaces={this.props.userGroups} />
              <div className="spacer" style={{ width: "40px" }} />
              {/* <div
                style={{
                  border: "1px solid #CCC",
                  padding: "4px",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column"
                }}
              > */}
              {this.renderPbiRoles()}
              {/* </div> */}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden"
            }}
            className="applied-groups"
          >
            <div
              style={{
                paddingBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "30px"
              }}
            >
              Applied Group Permissions
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden"
              }}
            >
              <AppliedWorkspacesPanel />
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleCreate(inputValue) {
    //remove non alphanumeric characters
    inputValue = inputValue.replace(/[\W_]+/g, "");
    this.props.addNewClient(inputValue);
  }
  updateInputValue(evt) {
    this.setState({
      //remove non alphanumeric characters
      inputValue: evt.target.value.replace(/[\W_]+/g, "")
    });
  }
  onInputChange(option) {
    return option.replace(/[\W_]+/g, "");
  }

  render() {
    const { clients } = this.props;
    const { selectedClient, showAddClientModal, showDeleteRoleConfirm } =
      this.state;
    return (
      <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <div className="page-title">Workspace & Workbook Mapping</div>
        <Modal isOpen={showAddClientModal}>
          <ModalHeader>Add Client</ModalHeader>

          <ModalBody>
            <input
              value={this.state.inputValue}
              onChange={evt => this.updateInputValue(evt)}
            />
          </ModalBody>

          <ModalFooter>
            <Button bsstyle="primary" onClick={this.addClient}>
              Save changes
            </Button>
            <Button onClick={this.handleClose}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showDeleteRoleConfirm}>
          <ModalHeader>Delete Model Role</ModalHeader>

          <ModalBody>
            Are you sure you want to delete this model role?
          </ModalBody>

          <ModalFooter>
            <Button onClick={this.removePbiRoleHandler} bsstyle="primary">
              Delete
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>
        {/* <div style={{ width: "100%" }}> */}
        {/* <div> */}
        <div className="client">
          <div className="client-text">Client:</div>
          <CreatableSelect
            key={JSON.stringify(clients)} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
            //filterOption={() => true}
            className="dropdown"
            value={
              selectedClient //isClearable
            }
            onChange={this.handleChange}
            onInputChange={this.onInputChange.bind(this)}
            options={clients}
            onCreateOption={
              this.handleCreate //autoLoad={true}
            }
          />
          <div className="spacer" style={{ width: "10%" }} />
          <div
            className="ta-button ta-button-primary"
            onClick={() => this.setState({ showAddClientModal: true })}
          >
            +Add Client
          </div>
          <div className="action-buttons">
            <div
              className={
                this.props.unsavedChanges
                  ? "ta-button ta-button-primary"
                  : "ta-button ta-button-disabled"
              }
              onClick={this.props.unsavedChanges ? this.saveHandler : null}
            >
              Save
            </div>
            <div
              className={
                this.props.unsavedChanges
                  ? "ta-button ta-button-secondary"
                  : "ta-button ta-button-disabled"
              }
              onClick={this.props.unsavedChanges ? this.cancelHandler : null}
            >
              Cancel
            </div>
          </div>
        </div>
        <div
          className="ovis-tab-view"
          style={{ display: "flex", width: "100%", overflow: "hidden" }}
        >
          {this.renderTab()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient,
  userGroups: state.groupReducer.userGroups,
  searchGroups: state.groupReducer.searchGroups,
  selectedGroup: state.groupReducer.selectedGroup,
  cLoading: state.clientReducer.loading,
  error: state.clientReducer.error,
  ugLoading: state.groupReducer.loading,
  ugError: state.groupReducer.error,
  allWorkspaces: state.workspaceReducer.allWorkspaces,
  allLoading: state.workspaceReducer.allLoading,
  unsavedChanges: state.groupReducer.unsavedChanges,
  cancelGroupChanges: state.groupReducer.cancelGroupChanges,
  modelRoles: state.groupReducer.modelRoles
});

const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  getAllWorkspacesForClient(client) {
    dispatch(getAllWorkspacesForClient(client));
  },
  getGroups(client) {
    dispatch(getGroups(client));
  },
  saveGroup(client, groups) {
    dispatch(saveGroup(client, groups));
  },
  resetAllStatus(workspaces) {
    dispatch(resetAllStatus(workspaces));
  },
  setClient(client) {
    dispatch(setClient(client));
  },
  setWorkspaces(workspaces) {
    dispatch(setWorkspaces(workspaces));
  },
  addNewClient(client) {
    dispatch(addNewClient(client));
  },
  setUnsavedGroup(group, workspaces, modelRoles) {
    dispatch(setUnsavedGroup(group, workspaces, modelRoles));
  },
  cancelGroupChanges() {
    dispatch(cancelGroupChanges());
  },
  setModelRoles(rRoles) {
    dispatch(setModelRoles(rRoles));
  },
  addModelRole(client, group, roleName) {
    dispatch(addModelRole(client, group, roleName));
  },
  removeModelRole(client, groupId, roleId) {
    dispatch(removeModelRole(client, groupId, roleId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceMapping);
