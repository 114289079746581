import React, { Component } from "react";

import { Icon } from "react-icons-kit";
import { trash } from "react-icons-kit/iconic/trash";

export default class PBIRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: false
    };
  }

  // removePbiRoleHandler() {
  //   const { rmRole } = this.state;
  //   let pbiRoles = this.props.modelRoles;
  //   let newPbiRoles = pbiRoles.filter(domItem => {
  //     return domItem !== rmRole;
  //   });
  //   this.props.setModelRoles(newPbiRoles);
  //   this.props.removeModelRole(
  //     this.props.selectedClient.value,
  //     this.props.selectedGroup,
  //     rmRole.id
  //   );
  //   this.handleClose();
  //   //this.props.setUnsavedGroup(this.props.selectedGroup, ws, newPbiRoles);
  //   //this.props.getGroups(this.props.selectedClient.value);
  // }
  render() {
    return (
      <div>
        <div
          key={this.props.index}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <input
            type="checkbox"
            style={{ marginRight: "4px" }}
            checked={this.props.role.checked}
            onChange={event => {
              this.props.checkboxChangeHandler(this.props.role, this.props.ws);
            }}
          />
          <div style={{ width: "90%" }}>{this.props.role.name}</div>
          <Icon
            style={{
              cursor: "pointer",
              paddingLeft: "7px",
              color: "rgba(0, 126, 167, 1)"
            }}
            size={13}
            onClick={event => {
              this.props.showDeleteRoleModal(this.props.role);
            }}
            icon={trash}
          />
        </div>
      </div>
    );
  }
}
