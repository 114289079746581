import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCustomApps,
  createCustomApp,
  getCustomAppObjects,
  saveCustomApp,
  setApp,
  deleteCustomApp,
  reorderItems,
  filterAvailableObjects,
  addGrouping,
  setSearchAppObjects,
  setSelectedAppObjects
} from "../../reducers/customApplicationReducer";
import {
  getGroups,
  filterGroups,
  setGroupSelection
} from "../../reducers/groupReducer";
import Select from "react-select";

import {
  AppChartAnalytics,
  AppChartUser,
  AppChartBar,
  AppChartLine,
  AppChartLineDown,
  AppPie,
  LeftArrow
} from "../../lib/icons";
import axios from "axios";

import Checkbox from "rc-checkbox";
//import { toast } from "react-toastify";
//import { cloneDeep, template } from "lodash";
//import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import SortableTree from "react-sortable-tree";
// import "@nosferatu500/react-sortable-tree/style.css";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { getNodeAtPath } from "react-sortable-tree";

import "./style.css";
import { cloneDeep } from "lodash";
import Stepper from "../stepper";
// import { Left } from "react-bootstrap/lib/Media";
//import { orderBy } from "lodash";

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "4px",
  margin: `0 0 ${grid}px 0`,
  height: "80px",
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  border: "1px solid #dbddde",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#f7f8fa",
  padding: grid
  // width: 250,
});
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class EditCustomApplication extends Component {
  constructor(props) {
    super(props);
    let sOptions = [
      {
        id: 1,
        value: "AppChartAnalytics",
        label: (
          <div>
            <AppChartAnalytics
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 2,
        value: "AppChartUser",
        label: (
          <div>
            <AppChartUser
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 3,
        value: "AppChartBar",
        label: (
          <div>
            <AppChartBar
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 4,
        value: "AppChartLine",
        label: (
          <div>
            <AppChartLine
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 5,
        value: "AppChartLineDown",
        label: (
          <div>
            <AppChartLineDown
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      },
      {
        id: 6,
        value: "AppPie",
        label: (
          <div>
            <AppPie
              height={"20px"}
              // width={"24px"}
              color={"rgba(88, 89, 91, 1)"}
              viewBox={"-1 2 24 24"}
            />
          </div>
        )
      }
    ];
    this.state = {
      groupPermissions: [],
      init: true,
      showCreateGroupModal: false,
      groupName: "",
      curGroupId: 1,
      options: sOptions,
      curSelection: sOptions[0],
      thumbnails: [],
      removeImgArr: [],
      showPrivateBookmarks: false,
      maxReportTiles: 6
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.addGrouping = this.addGrouping.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.removeNode = this.removeNode.bind(this);
    this.textTitle = React.createRef();
    this.textDesc = React.createRef();
    this.groupName = React.createRef();
    this.renderThumbs = this.renderThumbs.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleMaxTilesValueChange = this.handleMaxTilesValueChange.bind(this);
  }
  componentDidMount() {
    const { selectedClient, newApp, customAppToEdit } = this.props;

    if (newApp) {
      this.props.getCustomAppObjects(selectedClient.value, "");
    } else {
      this.props.getCustomAppObjects(selectedClient.value, customAppToEdit);
    }
    //
  }

  componentDidUpdate() {
    if (this.props.selectedCustomApp.title !== undefined) {
      if (this.state.init && !this.props.newApp) {
        this.textTitle.current.value = this.props.selectedCustomApp.title;
        this.textDesc.current.value = this.props.selectedCustomApp.description;
        this.props.setGroupSelection(
          this.props.selectedCustomApp.groupPermissions
        );

        let maxTiles = 6;
        if (this.props.selectedCustomApp.maxReportTiles !== undefined) {
          maxTiles = this.props.selectedCustomApp.maxReportTiles;
        }
        this.setState({
          groupPermissions: this.props.selectedCustomApp.groupPermissions,
          curSelection:
            this.state.options[this.props.selectedCustomApp.icon - 1],
          init: false,
          thumbnails: this.props.selectedCustomApp.thumbnails,
          showPrivateBookmarks:
            this.props.selectedCustomApp.showPrivateBookmarks,
          maxReportTiles: maxTiles
        });
      }
    }
  }
  handleInputChange(type) {
    if (type === "groups") {
      this.props.filterGroups(this.groupSearch.value, this.props.userGroups);
    } else if (type === "groupName") {
      this.setState({ groupName: this.groupName.current.value });
    } else {
      if (this.objSearch.value !== "") {
        this.props.filterAvailableObjects(
          this.objSearch.value,
          this.props.searchAppObjects
        );
      } else {
        this.props.filterAvailableObjects(
          this.objSearch.value,
          this.props.availableCustomAppObjects
        );
      }
    }
  }
  checkboxChangeHandler(event, group) {
    console.log(group);

    let nGroups = this.state.groupPermissions;

    if (event.target.checked === true) {
      nGroups.push(group.id);
      this.setState({ groupPermissions: nGroups });
      this.props.setGroupSelection(nGroups);
      //event.target.checked = !event.target.checked;
    } else {
      nGroups = nGroups.filter(fGroup => {
        return fGroup !== group.id;
      });
      this.setState({ groupPermissions: nGroups });
      this.props.setGroupSelection(nGroups);
      //event.target.checked = !event.target.checked;
    }
  }

  renderUserGroups() {
    const { searchGroups } = this.props;
    if (searchGroups.length > 0) {
      return searchGroups.map((group, index) => {
        return (
          <div key={index}>
            <Checkbox
              key={index}
              checked={group.customAppchecked}
              onChange={event => {
                this.checkboxChangeHandler(event, group);
              }}
              style={{ marginRight: "10px" }}
            />
            {group.name}
          </div>
        );
      });
    }
  }

  async saveHandler() {
    const {
      availableCustomAppObjects,
      selectedClient,
      selectedCustomAppObjects
    } = this.props;
    let title = this.textTitle.current.value;
    let desc = this.textDesc.current.value;
    let objects = [];

    availableCustomAppObjects.forEach((item, idx) => {
      if (item.selected === true && item.title === "Files") {
        objects.push(item);
      } else if (item.type === "folder" || item.type === "tableau") {
        let selectedChildren = item.children.filter(child => {
          if (child.type === "folder") {
            child.children = child.children.filter(gChild => {
              if (gChild.type === "folder") {
                gChild.children = gChild.children.filter(ggChild => {
                  return ggChild.selected;
                });
                return gChild.children.length > 0;
              } else {
                return gChild.selected;
              }
            });
            return child.children.length > 0;
          } else if (child.type.includes("Groupings")) {
            return false;
          } else {
            return child.selected;
          }
        });

        if (selectedChildren.length > 0) {
          item.children = selectedChildren;
          objects.push(item);
        }
      }
    });

    let nOrder = selectedCustomAppObjects.map(item => {
      if (item.type === "Files") {
        return "Files";
      } else if (item.type === "grouping") {
        item.order = [];
        item.children = item.children.filter(child => {
          return item.type !== undefined;
        });
        item.children = item.children.map(cItem => {
          if (cItem.path === undefined) {
            item.order.push(cItem.title);
          } else {
            let pathItems = cItem.path.split("/");

            let toplvl = pathItems[0];

            let parent = "";

            availableCustomAppObjects.forEach(aItem => {
              if (aItem.name === undefined) {
              }
              if (aItem.name === toplvl) {
                if (aItem._id === undefined) {
                  parent = aItem.id;
                } else {
                  parent = aItem._id;
                }
              }
            });
            item.order.push(cItem.id);
            if (cItem._id === undefined) {
              cItem.pathIds = parent + "/" + cItem.id;
            } else {
              cItem.pathIds = parent + "/" + cItem._id;
            }
            if (!cItem.type.includes("Groupings")) {
              cItem.type = cItem.type + "Groupings";
            }
          }
          return cItem;
        });
        objects.push(item);
      }
      return item.id;
    });
    let thumbnails = cloneDeep(this.state.thumbnails);
    thumbnails = thumbnails.map(item => {
      item.link = item.link.split("?")[0];
      return item;
    });
    let saveObj = {
      title,
      desc,
      objects,
      id: this.props.selectedCustomApp.id,
      thumbnails: thumbnails,
      icon: this.state.curSelection.id,
      groupPermissions: this.state.groupPermissions,
      showPrivateBookmarks: this.state.showPrivateBookmarks,
      maxReportTiles: this.state.maxReportTiles
    };

    if (this.state.removeImgArr.length > 0) {
      for (var item of this.state.removeImgArr) {
        await axios
          .post(
            `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/removeCustomAppImage`,
            item,
            {
              headers: {
                ClientToken: localStorage.getItem("clientToken")
              }
            }
          )
          .then(res => {});
      }

      this.setState({ removeImgArr: [] });
    }
    this.props.saveCustomApp(selectedClient.value, saveObj, nOrder);
    this.props.setApp({});
    this.props.setGroupSelection([]);
    this.props.setSelectedAppObjects([]);
    this.props.setSearchAppObjects([]);
    this.props.cancelHandler();
  }
  handleClose() {
    this.groupName.current.value = "";
    this.setState({ showCreateGroupModal: false, groupName: "" });
  }
  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
  handleMaxTilesValueChange(newValue) {
    this.setState({ maxReportTiles: newValue });
  }

  addGrouping() {
    if (this.state.groupName !== "") {
      let groupObj = {
        name: this.groupName.current.value,
        title: this.groupName.current.value,
        order: [],
        children: [{}],
        type: "grouping",
        selected: true,
        id: this.uuidv4().replace(/-/g, "")
      };
      groupObj.path = groupObj.id + "/";
      this.props.addGrouping(groupObj);
      this.setState({ curGroupId: this.state.curGroupId + 1 });
      this.handleClose();
    }
  }
  removeNode(node) {
    const { selectedCustomAppObjects, searchAppObjects } = this.props;

    let newAppObjects = cloneDeep(selectedCustomAppObjects);
    let newSearchObjects = cloneDeep(searchAppObjects);

    let pathSplit = node.node.path.split("/");
    let toplvl, twolvl, threelvl, fourlvl;

    toplvl = node.node.path.split("/")[0];
    twolvl = node.node.path.split("/")[1];
    if (node.node.type.includes("Grouping")) {
      newAppObjects = newAppObjects.map(item => {
        if (item.type === "grouping") {
          item.children = item.children.filter(child => {
            if (child.id !== node.node.id) {
              return true;
            } else {
              var index = item.order.indexOf(child.id);
              item.order.splice(index, 1);

              return false;
            }
          });
        }
        return item;
      });

      if (pathSplit.length === 2) {
        newSearchObjects = newSearchObjects.map(item => {
          if (item._id === toplvl) {
            item.children = item.children.map(child => {
              if (child._id === twolvl) {
                child.selected = false;
              }
              return child;
            });
          }
          return item;
        });
      } else if (pathSplit.length === 3) {
        threelvl = node.node.path.split("/")[2];
        newSearchObjects = newSearchObjects.map(item => {
          if (item._id === toplvl) {
            item.children = item.children.map(child => {
              if (child._id === twolvl) {
                child.children = child.children.map(gChild => {
                  if ((gChild._id = threelvl)) {
                    child.selected = false;
                  }
                  return gChild;
                });
              }
              return child;
            });
          }
          return item;
        });
      } else if (pathSplit.length === 4) {
        fourlvl = node.node.path.split("/")[3];
        newSearchObjects = newSearchObjects.map(item => {
          if (item._id === toplvl) {
            item.children = item.children.map(child => {
              if (child._id === twolvl) {
                child.children = child.children.map(gChild => {
                  if ((gChild._id = threelvl)) {
                    gChild.children = gChild.children.map(sChild => {
                      if (sChild._id === fourlvl) {
                        child.selected = false;
                      }
                      return sChild;
                    });
                  }
                  return gChild;
                });
              }
              return child;
            });
          }
          return item;
        });
      }
    } else {
      newAppObjects = newAppObjects.filter(item => {
        return node.node.id !== item.id;
      });
      if (pathSplit.length === 2) {
        newSearchObjects = newSearchObjects.map(item => {
          if (item.name === toplvl) {
            item.children = item.children.map(child => {
              if (child.name === twolvl) {
                child.selected = false;
              }
              return child;
            });
          }
          return item;
        });
      } else if (pathSplit.length === 3) {
        threelvl = node.node.path.split("/")[2];
        newSearchObjects = newSearchObjects.map(item => {
          if (item.name === toplvl) {
            item.children = item.children.map(child => {
              if (child.name === twolvl) {
                child.children = child.children.map(gChild => {
                  if ((gChild.name = threelvl)) {
                    child.selected = false;
                  }
                  return gChild;
                });
              }
              return child;
            });
          }
          return item;
        });
      } else if (pathSplit.length === 4) {
        fourlvl = node.node.path.split("/")[3];
        newSearchObjects = newSearchObjects.map(item => {
          if (item.name === toplvl) {
            item.children = item.children.map(child => {
              if (child.name === twolvl) {
                child.children = child.children.map(gChild => {
                  if ((gChild.name = threelvl)) {
                    gChild.children = gChild.children.map(sChild => {
                      if (sChild.name === fourlvl) {
                        child.selected = false;
                      }
                      return sChild;
                    });
                  }
                  return gChild;
                });
              }
              return child;
            });
          }
          return item;
        });
      }
    }

    this.props.setSelectedAppObjects(newAppObjects);
    this.props.setSearchAppObjects(newSearchObjects);
  }
  handleChange = selectedOption => {
    this.setState({
      curSelection: selectedOption
    });
  };
  removeImage(image) {
    const { removeImgArr } = this.state;
    let newImgArr = cloneDeep(removeImgArr);

    let newThumbs = this.state.thumbnails.filter(item => {
      return item.id !== image.id;
    });
    let containerName = image.link.split("/")[3];
    let fileName = image.link.split("/")[4].split("?")[0];
    newImgArr.push({
      containerName,
      fileName,
      id: image.id,
      customApplication: this.props.selectedCustomApp.id
    });
    this.setState({
      thumbnails: newThumbs,
      removeImgArr: newImgArr
    });
  }
  handleselectedFile = event => {
    const { thumbnails } = this.state;
    event.preventDefault();

    const data = new FormData();
    data.append("image", event.target.files[0]);
    data.append("name", "Upload");

    data.append("customApplication", this.props.selectedCustomApp.title);
    data.append("client", this.props.selectedClient.value);
    data.append("width", 325);
    data.append("height", 183);

    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/uploadAndResizeApplicationImage`,
        data,
        {
          headers: {
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(res => {
        let newThumbs = cloneDeep(thumbnails);
        newThumbs.push(res.data.data);

        this.setState({ thumbnails: newThumbs });
        // this.setState({
        //   imgSrc: res.data.data.link,
        //   imgHeight: res.data.data.height,
        //   imgWidth: res.data.data.width
        // });
        // let sasRm = res.data.data.link.split("?")[0];
        // let sasToken = res.data.data.link.split("?")[1];
        // this.props.saveImg(
        //   this.state.selectedWorkspace,
        //   this.state.selectedReport,
        //   sasRm,
        //   sasToken
        // );
        //console.log(res.statusText);
      });
  };
  renderThumbs(thumb) {
    const { thumbnails } = this.state;
    if (thumb !== undefined && thumb.link !== undefined) {
      return (
        <div
          key={thumb.id}
          style={{ display: "flex", flexDirection: "row", height: "100%" }}
        >
          <div
            style={{
              textAlign: "center",
              width: "100%",
              backgroundImage: "url(" + thumb.link + ")",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}
          ></div>
          <div
            style={{
              cursor: "pointer",
              marginTop: "24px",
              position: "relative",
              left: "-5px"
            }}
            onClick={() => {
              this.removeImage(thumb);
            }}
          >
            x
          </div>
        </div>
      );
    } else if (thumbnails.length > 0) {
      return thumbnails.map(thumb => {
        return (
          <div key={thumb.id} style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                backgroundImage: "url(" + thumb.link + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            ></div>
            <div
              style={{
                cursor: "pointer",
                marginTop: "24px",
                position: "relative",
                left: "-5px"
              }}
              onClick={() => {
                this.removeImage(thumb);
              }}
            >
              x
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  }
  render() {
    const { showCreateGroupModal, maxReportTiles } = this.state;
    let clientName = this.props.selectedClient.value.split("+")[0];
    let disabledSave = true;

    if (this.textTitle.current !== null) {
      if (this.textTitle.current.value !== "") {
        disabledSave = false;
      }
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Modal isOpen={showCreateGroupModal}>
          <ModalHeader>Create Group</ModalHeader>

          <ModalBody>
            <div>Group Name</div>
            <input
              onChange={() => {
                this.handleInputChange("groupName");
              }}
              ref={this.groupName}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              className={
                "ta-button-primary ta-button-primary " +
                (this.state.groupName !== "" ? "" : "disabled")
              }
              onClick={() => {
                this.addGrouping();
              }}
              color="primary"
            >
              Create
            </Button>

            <Button onClick={this.handleClose}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div style={{ fontWeight: "bold", marginBottom: "20px" }}>
          Manage Custom Applications
        </div>
        <div>Editing Custom Application for client: {clientName}</div>
        <div style={{ borderBottom: "1px solid #CCC", marginTop: "20px" }} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            paddingTop: "10px",
            borderBottom: "1px solid #CCC"
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <Checkbox
              checked={this.state.showPrivateBookmarks}
              ref={i => (this.showPrivateBookmarks = i)}
              onChange={event => {
                this.setState({
                  showPrivateBookmarks: !this.state.showPrivateBookmarks
                });
              }}
              style={{ marginRight: "5px" }}
            />
            Enable Personal Bookmarks for all Reports in Workspace
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="number-circle">1</div>
            <div>Set application Title,Icon, and Description</div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: "50px" }}>
              <div style={{ fontWeight: "bold" }}>Application Title</div>
              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                This title will be visible in the application menu and
                navigation bar
              </div>
              <input
                onChange={evt => {
                  if (evt.target.value === "") {
                    disabledSave = true;
                  } else {
                    disabledSave = false;
                  }
                }}
                ref={this.textTitle}
              />
            </div>
            <div>
              <div style={{ fontWeight: "bold" }}>Application Icon</div>
              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                This icon will be visible in the application menu and navigation
                bar
              </div>
              <Select
                key={this.state.options} //ref="selectClient" //onBlurResetsInput={false} //onSelectResetsInput={false}
                //filterOption={() => true}
                className="select-icon-dd"
                value={
                  this.state.curSelection //isClearable
                }
                onChange={item => {
                  this.handleChange(item);
                }}
                options={this.state.options}
              />
            </div>
          </div>
          <div>
            <div>Application Description</div>
            <textarea style={{ width: "50%" }} ref={this.textDesc} />
          </div>
          <div>
            <div>Max Report Tiles on Homepage</div>
            <Stepper
              value={maxReportTiles}
              onChange={this.handleMaxTilesValueChange}
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "30px" }}
          >
            <div className="number-circle">2</div>
            <div>Assign application components: reports, content,apps</div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "30px"
              }}
            >
              <div style={{ fontWeight: "500", fontSize: "180x" }}>
                Application Components
              </div>
              <div>
                Drag Reports, Content Pages, etc. to the box below to create an
                application. Drag to reorder
              </div>
              <div
                className="ta-button ta-button-primary"
                style={{
                  width: "120px",
                  marginLeft: "228px",
                  marginBottom: "3px",
                  marginTop: "15px"
                }}
                onClick={() => {
                  this.setState({ showCreateGroupModal: true });
                }}
              >
                Add Grouping
              </div>
              <div style={{ display: "flex" }}>
                <div className="applied-objects-div">
                  <SortableTree
                    treeData={this.props.selectedCustomAppObjects}
                    onChange={treeData => {
                      // console.log("THE THING CHANGED");
                      // console.log(this.props.selectedCustomAppObjects);
                      // console.log(treeData);
                      this.props.setSelectedAppObjects(treeData);
                    }}
                    generateNodeProps={node => {
                      if (node.node.type === "drop") {
                        return null;
                      } else {
                        let nodePropsSelected = {
                          buttons: [
                            <div
                              className="close-button"
                              onClick={e => {
                                this.removeNode(node);
                              }}
                            >
                              X
                            </div>
                          ]
                        };

                        return nodePropsSelected;
                      }
                    }}
                    // onMoveNode={mNode => {
                    //   debugger;
                    // }}
                    canDrop={dNode => {
                      if (dNode.prevPath.length > 0) {
                        if (dNode.nextParent !== null) {
                          // console.log("NEXT PARENT");
                          // console.log(dNode.nextParent);
                          if (
                            dNode.nextParent.type === "file" ||
                            dNode.nextParent.type === "tableau" ||
                            dNode.nextParent.type === "Files" ||
                            dNode.nextParent.type === "tableauGroupings" ||
                            dNode.nextParent.type === "fileGroupings" ||
                            dNode.nextParent.type === "drop"
                          ) {
                            // console.log("RETURNING FALSE ON FIRST CHECK");
                            return false;
                          }
                        }
                        if (
                          (dNode.node.type === "file" ||
                            dNode.node.type === "tableau" ||
                            dNode.node.type === "Files" ||
                            dNode.node.type === "drop") &&
                          dNode.nextParent !== null &&
                          (dNode.nextParent.type === "file" ||
                            dNode.nextParent.type === "tableau" ||
                            dNode.nextParent.type === "Files" ||
                            dNode.nextParent.type === "drop")
                        ) {
                          // console.log("RETURNING FALSE ON dNODE");
                          return false;
                        }
                      }
                      if (!dNode.prevPath[0].toString().includes("-")) {
                        // console.log("PREV PATH TRUE");
                        // console.log(dNode);
                        if (dNode.node.type !== "drop") return true;
                        else return false;
                      }

                      let dupeCheck =
                        this.props.selectedCustomAppObjects.filter(item => {
                          return item.id === dNode.node.id;
                        });
                      // console.log("DUPE CHECK");
                      // console.log(dupeCheck);
                      // console.log(dNode.node);
                      // console.log("SELECTED Custom App Objects");
                      // console.log(this.props.selectedCustomAppObjects);
                      if (dupeCheck.length > 0) {
                        // console.log("RETURNING FALSE on DUPE CHECK");
                        return false;
                      } else {
                        // console.log("RETURN TRUE ON DUPE CHECK");
                        return true;
                      }
                    }}
                    maxDepth={3}
                    theme={FileExplorerTheme}
                    dndType="dragObject"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <LeftArrow
                    height={"80px"}
                    color={"rgba(186,186,186,1)"}
                    style={{ marginLeft: "60px" }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: "500", fontSize: "180x" }}>
                Available Objects
              </div>
              <div>
                Add objects to the application by dragging to the application
                components box on the right. Objects may include reports, base
                applications, content pages, etc.
              </div>
              <input
                className="input-box"
                placeholder="search"
                ref={input => (this.objSearch = input)}
                onChange={() => {
                  this.handleInputChange("objs");
                }}
                style={{
                  width: "34.5%",
                  marginTop: "20px",
                  marginBottom: "3px"
                }}
              />
              <div
                className="available-objects-div"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #ccc"
                }}
              >
                <SortableTree
                  treeData={this.props.searchAppObjects}
                  getNodeKey={nKey => {
                    return nKey.treeIndex + "-" + nKey.node.id;
                  }}
                  onChange={treeData => {
                    this.props.setSearchAppObjects(treeData);
                  }}
                  dndType="dragObject"
                  theme={FileExplorerTheme}
                  shouldCopyOnOutsideDrop={true}
                  canDrop={dNode => {
                    return false;
                  }}
                  generateNodeProps={node => {
                    // debugger;
                    let title = "";
                    if (
                      node.node.alias === undefined ||
                      node.node.alias === ""
                    ) {
                      if (
                        node.node.name === undefined ||
                        node.node.name === ""
                      ) {
                        title = node.node.title;
                      } else {
                        title = node.node.name;
                      }
                    } else {
                      if (
                        node.node.name === undefined ||
                        node.node.name === ""
                      ) {
                        title = node.node.title + " (" + node.node.alias + ")";
                      } else {
                        title = node.node.name + " (" + node.node.alias + ")";
                      }
                    }
                    let nodePropsSelected = {
                      title: [
                        <div
                          className="selected-node"
                          key={node.treeIndex + "-" + node.node.id}
                        >
                          {title}
                          <div>*</div>
                        </div>
                      ],
                      buttons: []
                    };
                    let nodeProps = {
                      title: [
                        <div key={node.treeIndex + "-" + node.node.id}>
                          {title}
                        </div>
                      ],
                      buttons: []
                    };

                    if (
                      node.node.selected === false ||
                      node.node.type === "folder" ||
                      node.node.selected === null ||
                      node.node.selected === undefined
                    ) {
                      return nodeProps;
                    } else {
                      return nodePropsSelected;
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "350px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px"
              }}
            >
              <div className="number-circle">3</div>
              <div>Add Application Thumbnails</div>
            </div>
            <div
              style={{
                fontStyle: "italic",
                fontSize: "11px",
                paddingLeft: "23px",
                marginTop: "-8px"
              }}
            >
              Minimum recommended image size 325 x 183 px. The first picture
              will become the main default landing image. Drag to reorder.
            </div>

            <div
              style={{
                height: "200px",
                border: "1px solid #ccc",
                marginTop: "10px",
                marginBottom: "10px",
                overflowY: "scroll"
              }}
            >
              <DragDropContext
                onDragEnd={result => {
                  // dropped outside the list
                  if (!result.destination) {
                    return;
                  }

                  const items = reorder(
                    this.state.thumbnails,
                    result.source.index,
                    result.destination.index
                  );

                  this.setState({
                    thumbnails: items
                  });
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.thumbnails.map((item, index) => {
                        return (
                          <Draggable
                            key={item.id.replace(/[^\d.-]/g, "")}
                            draggableId={parseInt(
                              item.id.replace(/[^\d.-]/g, ""),
                              10
                            )}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {this.renderThumbs(item)}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <label
                className="ta-button ta-button-primary"
                style={{ width: "70px" }}
                htmlFor="upload"
              >
                Upload
              </label>
              <input
                hidden={true}
                id="upload"
                ref
                type="file"
                // disabled={
                //   this.props.searchAppObjects.length === 0 ? "disabled" : ""
                // }
                name="Upload"
                onChange={this.handleselectedFile}
                onClick={event => {
                  event.target.value = null;
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "350px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px"
              }}
            >
              <div className="number-circle">4</div>
              <div>Choose which user groups can access this application</div>
            </div>
            <div>Application Group Permissions</div>
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.groupSearch = input)}
              onChange={() => {
                this.handleInputChange("groups");
              }}
              style={{ width: "100%" }}
            />
            <div
              style={{
                height: "100px",
                border: "1px solid #ccc",
                marginTop: "10px",
                marginBottom: "10px",
                overflowY: "scroll"
              }}
            >
              {this.renderUserGroups()}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "350px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px"
              }}
            >
              <div className="number-circle">5</div>
              <div>Save or discard your edits</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px"
              }}
            >
              <div
                className={
                  "ta-button ta-button-primary " +
                  (disabledSave ? "disabled" : "")
                }
                onClick={() => {
                  if (!disabledSave) this.saveHandler();
                }}
              >
                Save
              </div>
              <div
                onClick={() => {
                  this.props.setApp({});
                  this.props.setGroupSelection([]);
                  this.props.setSelectedAppObjects([]);
                  this.props.setSearchAppObjects([]);
                  this.setState({ removeImgArr: [] });
                  this.props.cancelHandler();
                }}
                className="ta-button"
                style={{ border: "1px solid #ccc" }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customApps: state.customAppReducer.customApps,
  availableCustomAppObjects: state.customAppReducer.availableCustomAppObjects,
  selectedCustomAppObjects: state.customAppReducer.selectedCustomAppObjects,
  loadedAppObjects: state.customAppReducer.loadedAppObjects,
  order: state.customAppReducer.order,
  clients: state.clientReducer.clients,
  selectedClient: state.clientReducer.selectedClient,
  files: state.filesReducer.files,
  userGroups: state.groupReducer.userGroups,
  searchGroups: state.groupReducer.searchGroups,
  selectedCustomApp: state.customAppReducer.selectedCustomApp,
  searchAppObjects: state.customAppReducer.searchAppObjects
});
const mapDispatchToProps = dispatch => ({
  getCustomApps(client) {
    dispatch(getCustomApps(client));
  },
  createCustomApp(client, appName) {
    dispatch(createCustomApp(client, appName));
  },
  getCustomAppObjects(client, appId) {
    dispatch(getCustomAppObjects(client, appId));
  },

  getGroups(client) {
    dispatch(getGroups(client));
  },
  filterGroups(filter, groups) {
    dispatch(filterGroups(filter, groups));
  },
  saveCustomApp(client, app, order) {
    dispatch(saveCustomApp(client, app, order));
  },
  setApp(app) {
    dispatch(setApp(app));
  },
  deleteCustomApp(client, app) {
    dispatch(deleteCustomApp(client, app));
  },
  setGroupSelection(sGroups) {
    dispatch(setGroupSelection(sGroups));
  },
  reorderItems(begin, end) {
    dispatch(reorderItems(begin, end));
  },
  filterAvailableObjects(filter, objs) {
    dispatch(filterAvailableObjects(filter, objs));
  },
  addGrouping(group) {
    dispatch(addGrouping(group));
  },
  setSearchAppObjects(objs) {
    dispatch(setSearchAppObjects(objs));
  },
  setSelectedAppObjects(treeData) {
    dispatch(setSelectedAppObjects(treeData));
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomApplication);
