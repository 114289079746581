import React, { Component } from "react";
import { connect } from "react-redux";
import "./style/style.css";
import { cloneDeep } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";

class AppliedWorkspacesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredGroups: this.props.userGroups
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.userGroups !== this.props.userGroups) {
      this.setState({ filteredGroups: this.props.userGroups });
    }
  }
  renderWorkspaces(group) {
    let newGroup = cloneDeep(group);
    if (newGroup.workspaces === undefined) {
      newGroup.workspaces = [];
    }
    newGroup.workspaces = newGroup.workspaces.filter(workspace => {
      return workspace.checked === true;
    });
    newGroup.workspaces.sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : "";
      const nameB = b.name ? b.name.toLowerCase() : "";
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    if (newGroup.workspaces === undefined || newGroup.workspaces.length === 0) {
      return <div className="ta-workspace-name">none</div>;
    } else {
      return newGroup.workspaces.map((workspace, index) => {
        return (
          <div key={index} className="ta-workspace-name">
            {workspace.name}
          </div>
        );
      });
    }
  }

  handleInputChange = () => {
    const searchValue = this.appliedGroupsSearch.value.toLowerCase();
    const filteredGroups = this.props.userGroups
      .map(group => {
        // Filter workspaces based on the checked property
        const selectedWorkspaces = group.workspaces.filter(
          workspace => workspace.checked === true
        );

        // Further filter the selected workspaces based on the search value
        const filteredWorkspaces = selectedWorkspaces.filter(workspace => {
          const workspaceName = workspace.name
            ? workspace.name.toLowerCase()
            : "";
          return workspaceName.includes(searchValue);
        });

        const groupNameMatches = group.name.toLowerCase().includes(searchValue);
        const searchForNone =
          "none".includes(searchValue) && selectedWorkspaces.length === 0;

        // Only return the group if it matches the search criteria
        if (
          groupNameMatches ||
          filteredWorkspaces.length > 0 ||
          searchForNone
        ) {
          return {
            ...group,
            workspaces: filteredWorkspaces
          };
        } else {
          return null;
        }
      })
      .filter(group => group !== null);

    this.setState({ filteredGroups });
  };
  render() {
    const { ugLoading } = this.props;
    const { filteredGroups } = this.state;
    if (!ugLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
          <input
            className="input-box"
            placeholder="search"
            ref={input => (this.appliedGroupsSearch = input)}
            onChange={this.handleInputChange}
            style={{ width: "60%", marginBottom: "10px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #CCC",
              padding: "4px",
              overflow: "auto"
            }}
          >
            {filteredGroups
              .sort((a, b) => {
                const nameA = a.name ? a.name.toLowerCase() : "";
                const nameB = b.name ? b.name.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map((group, index) => {
                return (
                  <div key={index}>
                    <div className="ta-group-name">{group.name}</div>
                    <div
                      className="ta-workspaces"
                      style={{ paddingLeft: "20px" }}
                    >
                      {this.renderWorkspaces(group)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    } else {
      return <div className="ta-info-text">Select a client</div>;
    }
  }
}

const mapStateToProps = state => ({
  loading: state.workspaceReducer.loading,
  allWorkspaces: state.workspaceReducer.allWorkspaces,
  allLoading: state.workspaceReducer.allLoading,
  userGroups: state.groupReducer.userGroups,
  ugLoading: state.groupReducer.loading
});

export default connect(mapStateToProps, "")(AppliedWorkspacesPanel);
