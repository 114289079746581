import axios from "axios";
import { cloneDeep } from "lodash";

/* -------------------- INITIAL STATE -------------------- */
const INITIAL_STATE = {
  clients: [],
  loading: true,
  error: null,
  selectedClient: null,
  availableApps: null,
  loadingApps: false,
  loadedApps: false,
  reportListType: "",
  authType: "",
  embedType: "",
  defaultAppListItems: [],
  defaultApp: null,
  selectedAppPerms: [],
  selectedApp: null,
  selectedHPRApps: [],
  runUpdateHPRApps: false,
  selectedDashboardReports: []
};

/* -------------------- ACTION TYPES -------------------- */
const FETCH_CLIENTS_BEGIN = "FETCH_CLIENTS_BEGIN";
const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
const FETCH_CLIENTS_FAILURE = "FETCH_CLIENTS_FAILURE";
const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";
const ADD_CLIENT_BEGIN = "ADD_CLIENT_BEGIN";
const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";
const FETCH_APPS_SUCCESS = "FETCH_APPS_SUCCESS";
const FETCH_APPS_BEGIN = "FETCH_APPS_BEGIN";
const SET_DEFAULT_APP_ITEMS = "SET_DEFAULT_APP_ITEMS";
const SET_DEFAULT_APP = "SET_DEFAULT_APP";
const LOAD_APP_PERMS = "LOAD_APP_PERMS";
const SET_APP_PERMS = "SET_APP_PERMS";
const SET_SELECTED_HPR_APPS = "SET_SELECTED_HPR_APPS";
const SET_RUN_UPDATE_HPR_APPS = "SET_RUN_UPDATE_HPR_APPS";

/* -------------------- ACTION CREATORS -------------------- */

const fetchClientsBegin = () => ({
  type: FETCH_CLIENTS_BEGIN
});
const fetchClientsSuccess = clients => {
  return { type: FETCH_CLIENTS_SUCCESS, payload: clients };
};
const fetchAppsBegin = () => ({
  type: FETCH_APPS_BEGIN
});
const fetchAppsSuccess = (
  apps,
  reportListType,
  authType,
  domain,
  mdmClientId,
  defaultApp,
  embedType
) => {
  return {
    type: FETCH_APPS_SUCCESS,
    payload: apps,
    reportListType,
    authType,
    embedType,
    domain,
    mdmClientId,
    defaultApp
  };
};
const fetchClientsError = error => ({
  type: FETCH_CLIENTS_FAILURE,
  payload: { error }
});
const setSelectedClient = client => {
  return { type: SET_SELECTED_CLIENT, payload: client };
};
const addClientBegin = () => ({
  type: ADD_CLIENT_BEGIN
});
const addClientSuccess = client => {
  return { type: ADD_CLIENT_SUCCESS, payload: client };
};
const addClientError = error => ({
  type: ADD_CLIENT_FAILURE,
  payload: { error }
});
const setDefaultAppListItems = items => {
  return { type: SET_DEFAULT_APP_ITEMS, payload: items };
};

const doSetDefaultApp = defaultApp => {
  return { type: SET_DEFAULT_APP, payload: defaultApp };
};
const doLoadAppPerms = app => {
  return { type: LOAD_APP_PERMS, payload: app };
};
const doSetAppPerms = (perms, selectAll) => {
  return { type: SET_APP_PERMS, perms, selectAll };
};
const selectedHPRAppsSuccess = apps => {
  return { type: SET_SELECTED_HPR_APPS, apps };
};
const doSetUpdateHPRApps = () => {
  return { type: SET_RUN_UPDATE_HPR_APPS };
};

/* -------------------- ASYNC ACTION CREATORS -------------------- */
export const getClients = () => {
  return dispatch => {
    dispatch(fetchClientsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getClients`,
        {
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(fetchClientsSuccess(response.data.clients));
      })
      .catch(error => dispatch(fetchClientsError(error)));
  };
};
export const getAvailableApps = client => {
  return dispatch => {
    dispatch(fetchAppsBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getAvailableApps`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        var defaultAppList = [];
        var perms = [];
        var availableApps = response.data.availableApps;
        var getMdm = [];
        var useFileFolderPermissions = response.data.useFileFolderPermissions;
        if (response.data.mdmClientId !== "") {
          getMdm = availableApps.filter(item => {
            return item.type === "mdh";
          });
        }

        if (getMdm.length > 0) {
          var clientResp = response;

          axios
            .post(
              `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getMdmApps`,
              {
                client
                //idToken: localStorage.getItem("id_token")
              },
              {
                headers: {
                  //Authorization: "Bearer " + localStorage.getItem("access_token")
                  ClientToken: localStorage.getItem("clientToken")
                }
              }
            )
            .then(response => {
              availableApps.forEach((item, idx) => {
                perms = item.permissions;
                if (perms === undefined) {
                  perms = [];
                }
                if (item.checked) {
                  if (item.type === "tableau") {
                  } else if (item.type === "powerBI") {
                  } else if (item.type === "files") {
                    defaultAppList.push({
                      label: item.title,
                      value: item.title,
                      name: item.title,
                      link: item.link,
                      permissions: perms,
                      useFileFolderPermissions: useFileFolderPermissions
                    });
                    availableApps[idx].useFileFolderPermissions =
                      useFileFolderPermissions;
                  } else if (item.type === "mdh") {
                    response.data.apps.forEach((app, index) => {
                      let newIdx = index + 1;
                      perms = app.permissions;
                      if (app.permissions === undefined) {
                        perms = [];
                      }
                      defaultAppList.push({
                        label: "MDH:" + app["APP_NAME"],
                        value: "MDH:" + app["APP_NAME"],
                        name: "MDH:" + app["APP_NAME"],
                        link: "/mdm/dataManagement/" + newIdx + "+0",
                        permissions: perms
                      });
                    });
                  } else {
                    defaultAppList.push({
                      label: item.title,
                      value: item.title,
                      name: item.title,
                      link: item.link,
                      permissions: perms
                    });
                  }
                }
              });
              let tableau = availableApps.filter(item => {
                if (item.type === "tableau" && item.checked === true) {
                  perms = item.permissions;
                }
                return item.type === "tableau" && item.checked === true;
              });
              let powerBI = availableApps.filter(item => {
                if (item.type === "powerBI" && item.checked === true) {
                  perms = item.permissions;
                }
                return item.type === "powerBI" && item.checked === true;
              });
              if (perms === undefined) {
                perms = [];
              }

              if (tableau > 0 || powerBI.length > 0) {
                defaultAppList.push({
                  label: "Reporting",
                  value: "Reporting",
                  name: "Reporting",
                  link: "/reports/landing",
                  permissions: perms
                });
              }
              // defaultAppList.push({
              //   label: "Homepage Apps",
              //   value: "Homepage Apps",
              //   name: "Homepage Apps",
              //   link: "/homePageApps",
              //   permissions: hpPerms
              // });

              dispatch(setDefaultAppListItems(defaultAppList));

              dispatch(
                fetchAppsSuccess(
                  availableApps,
                  clientResp.data.reportListType,
                  clientResp.data.authType,
                  clientResp.data.domain,
                  clientResp.data.mdmClientId,
                  clientResp.data.defaultApp,
                  clientResp.data.embedType
                )
              );
            });
        } else {
          availableApps.forEach((item, idx) => {
            perms = item.permissions;
            if (perms === undefined) {
              perms = [];
            }
            if (item.checked) {
              if (item.type === "tableau") {
              } else if (item.type === "powerBI") {
              } else if (item.type === "mdh") {
              } else if (item.type === "files") {
                defaultAppList.push({
                  label: item.title,
                  value: item.title,
                  name: item.title,
                  link: item.link,
                  permissions: perms,
                  useFileFolderPermissions
                });
                availableApps[idx].useFileFolderPermissions =
                  useFileFolderPermissions;
              } else {
                defaultAppList.push({
                  label: item.title,
                  value: item.title,
                  name: item.title,
                  link: item.link,
                  permissions: perms
                });
              }
            }
          });
          let tableau = availableApps.filter(item => {
            if (item.type === "tableau" && item.checked === true) {
              perms = item.permissions;
            }
            return item.type === "tableau" && item.checked === true;
          });
          let powerBI = availableApps.filter(item => {
            if (item.type === "powerBI" && item.checked === true) {
              perms = item.permissions;
            }
            return item.type === "powerBI" && item.checked === true;
          });
          if (perms === undefined) {
            perms = [];
          }
          if (tableau > 0 || powerBI.length > 0) {
            defaultAppList.push({
              label: "Reporting",
              value: "Reporting",
              name: "Reporting",
              link: "/reports/landing",
              permissions: perms
            });
          }

          dispatch(setDefaultAppListItems(defaultAppList));

          dispatch(
            fetchAppsSuccess(
              availableApps,
              response.data.reportListType,
              response.data.authType,
              response.data.domain,
              response.data.mdmClientId,
              response.data.defaultApp,
              response.data.embedType
            )
          );
        }
      })
      .catch(error => console.log(error));
  };
};
export const setClient = client => {
  return dispatch => {
    dispatch(setSelectedClient(client));
  };
};

export const saveClient = (
  client,
  apps,
  reportListType,
  domain,
  authType,
  mdmClientId,
  defaultApp,
  appsPerms,
  appsOrder,
  hprAppsOrder,
  embedType
) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/saveClient`,
        {
          client,
          apps,
          appsOrder,
          hprAppsOrder,
          reportListType,
          domain,
          authType,
          mdmClientId,
          defaultApp,
          appsPerms,
          embedType
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        //dispatch(addClientSuccess(response.data.client));
      })
      .catch(error => console.log(error));
  };
};
export const deleteClient = (client, hardDelete, removeFromAd) => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/deleteClient`,
        {
          client,
          hardDelete,
          removeFromAd
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(getClients());
      })
      .catch(error => dispatch(addClientError(error)));
  };
};
export const addNewClient = client => {
  return dispatch => {
    client = client.toLowerCase();
    dispatch(addClientBegin());
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/addClient`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        dispatch(addClientSuccess(response.data.client));
      })
      .catch(error => dispatch(addClientError(error)));
  };
};
export const setDefaultApp = defaultApp => {
  return dispatch => {
    dispatch(doSetDefaultApp(defaultApp));
  };
};

export const runExcelRefresh = client => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/runExcelRefresh`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        console.log(response);
        //dispatch(addClientSuccess(response.data.client));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
export const loadAppPerms = app => {
  return dispatch => {
    dispatch(doLoadAppPerms(app));
  };
};
export const setAppPerms = (perms, selectAll) => {
  return dispatch => {
    dispatch(doSetAppPerms(perms, selectAll));
  };
};
export const setDefaultAppList = list => {
  return dispatch => {
    dispatch(setDefaultAppListItems(list));
  };
};
export const getSelectedHPRApps = client => {
  return dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/getSelectedHPRApps`,
        {
          client
          //idToken: localStorage.getItem("id_token")
        },
        {
          headers: {
            //Authorization: "Bearer " + localStorage.getItem("access_token")
            ClientToken: localStorage.getItem("clientToken")
          }
        }
      )
      .then(response => {
        // console.log("SELECTED HPR APPS SUCCESS");
        // console.log(response.data.selected);
        dispatch(selectedHPRAppsSuccess(response.data.selected));
      })
      .catch(error => console.log("ERROR GETTING SELECTED HPR APPS"));
  };
};
export const setSelectedHPRApps = app => {
  return dispatch => {
    dispatch(selectedHPRAppsSuccess(app));
  };
};

export const setHPRUpdateStatus = () => {
  return dispatch => {
    dispatch(doSetUpdateHPRApps());
  };
};

/* -------------------- REDUCER -------------------- */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CLIENTS_BEGIN:
      return { ...state, loading: true, error: null };
    case FETCH_CLIENTS_SUCCESS:
      return { ...state, clients: action.payload, loading: false };
    case FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case SET_SELECTED_CLIENT:
      return { ...state, selectedClient: action.payload };
    case ADD_CLIENT_BEGIN:
      return { ...state, loading: true };
    case ADD_CLIENT_SUCCESS:
      let updateClients = state.clients;
      updateClients.push(action.payload);
      return {
        ...state,
        clients: updateClients,
        loading: false
      };
    case ADD_CLIENT_FAILURE:
      return {
        ...state
      };
    case FETCH_APPS_BEGIN:
      return { ...state, loadingApps: true, loadedApps: false };
    case FETCH_APPS_SUCCESS:
      let defaultApp = action.defaultApp;
      if (defaultApp === undefined) {
        defaultApp = { label: "Select ...", value: "Select ..." };
      }
      return {
        ...state,
        availableApps: action.payload,
        reportListType: action.reportListType,
        authType: action.authType,
        embedType: action.embedType,
        domain: action.domain,
        mdmClientId: action.mdmClientId,
        loadingApps: false,
        loadedApps: true,
        defaultApp
      };
    case SET_DEFAULT_APP_ITEMS:
      return { ...state, defaultAppListItems: action.payload };
    case SET_DEFAULT_APP:
      return { ...state, defaultApp: action.payload };
    case LOAD_APP_PERMS:
      let sAppPerm = cloneDeep(state.defaultAppListItems);
      sAppPerm = sAppPerm.filter(item => {
        return item.name === action.payload.name;
      });

      if (sAppPerm[0].permissions === undefined) {
        sAppPerm = [];
      } else {
        sAppPerm = sAppPerm[0];
      }

      return {
        ...state,
        selectedAppPerms: sAppPerm.permissions,
        selectedApp: sAppPerm
      };
    case SET_APP_PERMS:
      let saPerms = cloneDeep(state.selectedAppPerms);
      let idx = -1;
      let permChk = saPerms.filter((item, index) => {
        if (item === action.perms.id) {
          idx = index;
        }
        return item === action.perms.id;
      });

      if (action.selectAll !== undefined) {
        if (action.selectAll) {
          if (permChk.lengh > 0) {
          } else {
            saPerms.push(action.perms.id);
          }
        } else {
          if (permChk.length > 0) {
            saPerms.splice(idx, 1);
          }
        }
      } else {
        if (permChk.length > 0) {
          saPerms.splice(idx, 1);
        } else {
          saPerms.push(action.perms.id);
        }
      }
      let newApps = cloneDeep(state.defaultAppListItems);
      newApps = newApps.map(item => {
        if (item.name === state.selectedApp.name) {
          item.permissions = saPerms;
        }
        return item;
      });

      return {
        ...state,
        selectedAppPerms: saPerms,
        defaultAppListItems: newApps
      };
    case SET_SELECTED_HPR_APPS:
      return {
        ...state,
        selectedHPRApps: action.apps,
        runUpdateHPRApps: true
      };
    case SET_RUN_UPDATE_HPR_APPS:
      return {
        ...state,
        runUpdateHPRApps: false
      };

    default:
      return state;
  }
};
