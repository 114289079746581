import React, { Component } from "react";

import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { getClients } from "../../reducers/clientReducer";
import {
  getRefreshes,
  filterRefreshes
} from "../../reducers/excelRefreshReducer";
import LastRefreshCellRenderer from "./lastRefreshCellRenderer";
import axios from "axios";
import { RefreshIcon } from "../../lib/icons";
import { toast } from "react-toastify";

// var groupComparator = (item1, item2, node1, node2) => {
//   if (node1 === undefined) {
//     return item1;
//   }
//   var a = node1.data.groups[0].name.toLowerCase();
//   var b = node2.data.groups[0].name.toLowerCase();
//   return a > b ? 1 : a < b ? -1 : 0;
// };

class ExcelRefresh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Client Name",
          field: "clientName",
          editable: false,
          checkboxSelection: true,
          sortable: true
        },
        {
          headerName: "Last Excel Refresh",
          field: "lastExcelRefresh",
          editable: false,
          sortable: true
        },
        {
          headerName: "Last Refresh Status",
          field: "lastRefreshStatus",
          cellRenderer: "LastRefreshCellRenderer",
          sortable: true
          //comparator: groupComparator
        },
        { headerName: "# Files", field: "totalFiles", sortable: true },
        {
          headerName: "# Refresh Files",
          field: "refreshFiles",
          sortable: true
        },
        {
          headerName: "# Set Disabled",
          field: "disabledFiles",
          sortable: true
        },
        { headerName: "Status", field: "status", sortable: true }
      ],
      selectedRefreshes: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
  }
  componentDidMount() {
    this.props.getRefreshes();
    if (this.props.clients.length < 0) {
      this.props.getClients();
    }
  }
  handleInputChange() {
    this.props.filterRefreshes(this.search.value, this.props.refreshes);
  }
  onRowSelected(event, grid = "") {
    const { selectedRefreshes } = this.state;
    let chkRefreshes = selectedRefreshes.filter(item => {
      return item === event.data.clientName;
    });
    if (chkRefreshes.length === 0) {
      this.setState({
        selectedRefreshes: [...selectedRefreshes, event.data.clientName]
      });
    } else {
      let newRefreshes = selectedRefreshes.filter(item => {
        return item !== event.data.clientName;
      });
      this.setState({ selectedRefreshes: newRefreshes });
    }

    // if (grid === "main") {
    //   let selectedUsers = this.state.selectedUsers;
    //   let runPush = true;
    //   if (event.node.selected) {
    //     this.state.selectedUsers.forEach(user => {
    //       if (user.id === event.data._id) {
    //         runPush = false;
    //       }
    //     });
    //     if (event.data._id && runPush)
    //       selectedUsers.push({
    //         id: event.data._id,
    //         graphId: event.data.graphId,
    //         name: event.data.userName,
    //         groups: event.data.groups,
    //         status: event.data.status
    //       });
    //   } else {
    //     selectedUsers = selectedUsers.filter(user => {
    //       return user.id !== event.data._id;
    //     });
    //   }
    //   this.setState({ selectedUsers });
    // } else {
    //   let groups = this.state.groups;
    //   if (event.node.selected) {
    //     groups.push(event.data.id);
    //   } else {
    //     groups = groups.filter(group => {
    //       return group !== event.data.id;
    //     });
    //   }
    //   this.setState({ groups });
    // }
  }
  onGridReady(event, populateChecks = false) {
    var selectedUsers = this.state.selectedUsers;
    if (populateChecks) {
      event.api.forEachNode(node => {
        selectedUsers[0].groups.forEach(group => {
          if (node.data.id === group._id) {
            node.setSelected(true);
            return;
          } else if (node.data.id === group) {
            node.setSelected(true);
            return;
          }
        });
      }, this);
    }
    event.api.sizeColumnsToFit();
  }
  render() {
    if (!this.props.loaded) {
      return <div>Loading</div>;
    }
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div className="page-title">Excel Refresh</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px"
          }}
        >
          <div style={{ width: "25%" }}>
            <input
              className="input-box"
              placeholder="search"
              ref={input => (this.search = input)}
              onChange={this.handleInputChange}
              style={{ width: "100%" }}
            />
          </div>
          <div
            className="action-buttons"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <div
              className="ta-button ta-button-secondary"
              style={{ marginRight: "20px", display: "flex" }}
              onClick={() => {
                this.props.getRefreshes();
                this.setState({ selectedRefreshes: [] });
                toast.success("Refreshing page...");
              }}
            >
              <div style={{ marginTop: "-2px", marginRight: "4px" }}>
                <RefreshIcon color={"rgba(88, 89, 91, 1)"} height={"18"} />
              </div>
              Refresh Page
            </div>
            <div>Refresh Excel Files for Selected Clients:</div>
            <div
              onClick={() => {
                console.log(this.state.selectedRefreshes);
                console.log(this.props.clients);
                let fullClientNames = this.state.selectedRefreshes.map(item => {
                  let newClientName;
                  this.props.clients.forEach(client => {
                    if (client.label === item) {
                      newClientName = client.value;
                    }
                  });
                  return newClientName;
                });

                axios
                  .post(
                    `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/bulkRunExcelRefresh`,
                    {
                      clients: fullClientNames,
                      schedule: false
                    },
                    {
                      headers: {
                        ClientToken: localStorage.getItem("clientToken")
                      }
                    }
                  )
                  .then(response => {
                    if (response.data.message !== undefined) {
                      alert(response.data.message);
                    } else {
                      alert("Running");
                    }
                  });
              }}
              className="ta-button ta-button-primary"
            >
              Refresh Excel Files
            </div>
            <div
              onClick={() => {
                console.log(this.state.selectedRefreshes);
                console.log(this.props.clients);
                let fullClientNames = this.state.selectedRefreshes.map(item => {
                  let newClientName;
                  this.props.clients.forEach(client => {
                    if (client.label === item) {
                      newClientName = client.value;
                    }
                  });
                  return newClientName;
                });

                axios
                  .post(
                    `${process.env.REACT_APP_OVIS_SERVER}/api/ovis/bulkRunExcelRefresh`,
                    {
                      clients: fullClientNames,
                      schedule: true
                    },
                    {
                      headers: {
                        ClientToken: localStorage.getItem("clientToken")
                      }
                    }
                  )
                  .then(response => {
                    if (response.data.message !== undefined) {
                      alert(response.data.message);
                    } else {
                      alert("Scheduled");
                    }
                  });
              }}
              className="ta-button ta-button-primary"
            >
              Schedule Refresh
            </div>
          </div>
        </div>

        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-balham"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            rowData={this.props.searchRefreshes}
            rowSelection={"multiple"}
            enableSorting={true}
            enableFilter={false}
            onRowSelected={this.onRowSelected}
            onGridReady={event => this.onGridReady(event)}
            frameworkComponents={{
              LastRefreshCellRenderer: LastRefreshCellRenderer
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clients: state.clientReducer.clients,
  refreshes: state.refreshReducer.refreshes,
  loading: state.refreshReducer.loading,
  loaded: state.refreshReducer.loaded,
  searchRefreshes: state.refreshReducer.searchRefreshes
});
const mapDispatchToProps = dispatch => ({
  getClients() {
    dispatch(getClients());
  },
  getRefreshes() {
    dispatch(getRefreshes());
  },
  filterRefreshes(filter, refreshes) {
    dispatch(filterRefreshes(filter, refreshes));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ExcelRefresh);
